import { createReducer } from '../../commons/redux/createReducer';
import initialState from './ConsultaProcesso.initialState';
import * as actions from './ConsultaProcesso.actions';

const fetchConsultaProcesso = state => ({
  ...state,
  loadingConsulta: true,
});

const fetchConsultaProcessoRenderSucess = state => ({
  ...state,
  loadingConsulta: false,
});

const fetchConsultaProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  processo: action.processo,
});

const fetchConsultaProcessoError = (state, action) => ({
  ...state,
  error: action.error,
});

const fetchConsultaProcessoAtualizaPaginaAtualSuccess = (state, action) => ({
  ...state,
  paginaAtual: action.paginaAtual,
});

const fetchVinculoProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  lastVinculo: action.data.last,
  listaVinculo: action.data.content,
  cdProcesso: action.data.cdProcesso,
  totalElements: action.data.totalElements,
});

const fetchVinculoProcessoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
  listaVinculo: [],
  totalElements: 0,
});

const fetchArquivoConsultaProcessoSuccess = state => ({
  ...state,
  loadingConsulta: false,
});

const fetchArquivoConsultaProcessoError = (state, action) => ({
  ...state,
  error: action.error,
});

const fetchPopperIsOpen = state => ({
  ...state,
  popperIsOpen: !state.popperIsOpen,
});

const fetchFecharTodos = state => ({
  ...state,
  fecharTodos: !state.fecharTodos,
});

const ConsultaProcessoReducer = createReducer(initialState, {
  [actions.fetchConsultaProcessoAction().type]: fetchConsultaProcesso,
  [actions.fetchConsultaProcessoActionRenderSucess().type]: fetchConsultaProcessoRenderSucess,
  [actions.fetchConsultaProcessoActionSuccess().type]: fetchConsultaProcessoSuccess,
  [actions.fetchConsultaProcessoActionError().type]: fetchConsultaProcessoError,
  [actions.fetchConsultaProcessoAtualizaPaginaAtualActionSuccess().type]: fetchConsultaProcessoAtualizaPaginaAtualSuccess,
  [actions.fetchVinculoProcessoActionSuccess().type]: fetchVinculoProcessoSuccess,
  [actions.fetchVinculoProcessoActionError().type]: fetchVinculoProcessoError,
  [actions.fetchArquivoConsultaProcessoActionSuccess().type]: fetchArquivoConsultaProcessoSuccess,
  [actions.fetchArquivoConsultaProcessoActionError().type]: fetchArquivoConsultaProcessoError,
  [actions.fetchPopperisOpenAction().type]: fetchPopperIsOpen,
  [actions.fetchPopperFecharTodosAction().type]: fetchFecharTodos,
});

export default ConsultaProcessoReducer;
