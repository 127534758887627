import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import { Typography } from '@bayon/commons';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { formataNomeExibicaoDoc } from '../../utils/funcoesConsultaProcesso';

const styles = () => ({
  '@global': {
    '*::-webkit-scrollbar': { width: 5 },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: '#8b8778',
    },
  },
});

const PopperLinksDocs = function PopperLinksDocs(props) {
  const myRef = useRef();  
  const [anchorEl, setAnchorEl] = useState(null);
  const { lista, popperIsOpen, fecharTodos, callbackIsOpen, callbackFecharTodos } = props;  

  const open = Boolean(anchorEl) && popperIsOpen;
  const id = open ? 'simple-popper' : undefined;

  function handleClick(event) {
    callbackFecharTodos();
    callbackIsOpen();
    myRef.current = true;
    setAnchorEl(event.currentTarget);        
  }

  useEffect(() => {
    if (popperIsOpen && !myRef.current) {
      setAnchorEl(null);
    }
    myRef.current = false;
  }, [fecharTodos]);

  const manterAberto = (ev) => {    
    callbackIsOpen();
  };

  return (
    <div>
      <Link id="linkMais" onClick={handleClick}>mais</Link>
      <Popper id={id} open={open} anchorEl={anchorEl} transition onClick={manterAberto}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <Paper>
              <Typography style={{ padding: 12 }} size={12} weight="semibold">Documentos</Typography>
              <hr style={{ margin: 0 }} />              
              <div style={{ padding: 5 }}>
                {lista.map(item => (
                  <Grid key={item.movimentacao_pk.numero_sequencial} alignItems="center">
                    <Grid item xs={12} style={{ paddingTop: 3 }}>
                      <Typography size={12}>                          
                        <a href={item.url_documento} target="_blank" rel="noopener noreferrer">                            
                          {formataNomeExibicaoDoc(item.descricao_tipo_movimentacao, item.data_movimento)}                      
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </div>              
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

PopperLinksDocs.propTypes = {  
  lista: PropTypes.object.isRequired,
  popperIsOpen: PropTypes.bool.isRequired,
  fecharTodos: PropTypes.bool.isRequired,
  callbackIsOpen: PropTypes.func.isRequired,
  callbackFecharTodos: PropTypes.func.isRequired,
};

export default withStyles(styles)(PopperLinksDocs);
