import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@bayon/commons';
import * as actions from './Orgao.epics';

const Orgao = function Orgao({ loading, orgao, fetchOrgao, ...restProps }) {
  useEffect(() => {
    fetchOrgao();
  }, []);

  if (loading) return false;

  const filterOption = (candidate, input) => {
    if (!input) return true;
    return candidate.label.toUpperCase().includes(input.toUpperCase());
  };

  return (
    <Select
      size="medium"
      options={orgao.map(item => ({ value: item.orgao_pk.codigo_orgao, label: item.descricao, search: item.descricao }))}
      filterOption={filterOption}
      {...restProps}
    />
  );
};

Orgao.propTypes = {
  loading: PropTypes.bool.isRequired,
  orgao: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchOrgao: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: state.orgaoReducer.loading,
  orgao: state.orgaoReducer.orgao,
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(Orgao);
