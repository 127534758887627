import * as actions from './DistribuicaoProcesso.actions';
import initialState from './DistribuicaoProcesso.initialState';
import { createReducer } from '../../commons/redux/createReducer';

const fetchDistribuicaoProcesso = state => ({
  ...state,
  loading: true,
});

const fetchDistribuicaoProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  lastDistribuicao: action.data.last,
  listaDistribuicao: action.data.content,
  cdProcesso: action.data.cdProcesso,
  totalElements: action.data.total_elements,
  numeroPaginaDistribuicao: action.data.numeroPaginaDistribuicao,
});

const fetchDistribuicaoProcessoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
  listaDistribuicao: [],
  totalElements: 0,
});

const DistribuicaoProcessoReducer = createReducer(initialState, {
  [actions.fetchDistribuicaoProcessoAction().type]: fetchDistribuicaoProcesso,
  [actions.fetchDistribuicaoProcessoActionSuccess().type]: fetchDistribuicaoProcessoSuccess,
  [actions.fetchDistribuicaoProcessoActionError().type]: fetchDistribuicaoProcessoError,
});

export default DistribuicaoProcessoReducer;
