import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AngleDown from '@bayon/svg-icons/icons/AngleDown';
import AngleUp from '@bayon/svg-icons/icons/AngleUp';
import { Typography } from '@bayon/commons';
import * as actions from './ParteProcesso.epics';
import styles from '../../commons/styles';

function ParteProcesso(props) {
  const { classes, fetchLoadMore, fetchLoadLess, listaParte, cdProcesso, total, lastParte } = props;

  function loadMore(e) {
    e.preventDefault();
    fetchLoadMore(cdProcesso);
  }

  function loadLess(e) {
    e.preventDefault();
    fetchLoadLess(cdProcesso, listaParte);
  }

  return (
    <div className={classes.root}>
      <div className={classes.colorPadding}>
        <div className={classes.colorPaddingInterna}>
          <div className={classes.fontCabecalhoCard}>Partes interessadas</div>
          {!listaParte.length > 0 && (
            <div>
              <br />
              <div><Typography size={14} color="neutralTertiary">Não existem partes interessadas para este cadastro.</Typography></div>
            </div>
          )}
          {listaParte.length > 0 && (
            <div>
              <Hidden smDown>
                <Table>
                  <TableBody>
                    {listaParte.map(item => (
                      <>
                        <TableRow key={item.numero_sequencial}>
                          <TableCell className={[classes.tablecell, classes.unjLabel].join(' ')} style={{ minWidth: 150, fontWeight: 500, verticalAlign: 'top' }}>
                            {item.tipo_parte}
                          </TableCell>
                          <TableCell className={classes.tablecell} style={{ width: '90%', verticalAlign: 'top' }}>
                            <Typography size={14}>
                              {item.nome_pessoa}
                              {item.partes_representante && item.partes_representante.map(itemRepresentante => (
                                <div key={item.partes_representante.indexOf(itemRepresentante)}>
                                  {itemRepresentante.indexOf('(baixado)') > -1
                                    ? <Typography size={12} color="neutralSecondaryAlt">{itemRepresentante}</Typography>
                                    : <Typography size={12}>{itemRepresentante}</Typography>}
                                </div>
                              ))}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.rootCard}>
                  {listaParte.map(item => (
                    <Grid container key={item.numero_sequencial} className={classes.li}>
                      <Grid item xs={12} sm={3} className={classes.col}>
                        <div className={classes.unjLabel}>{item.tipo_parte}</div>
                        <div>{item.nome_pessoa}</div>
                        {item.partes_representante && item.partes_representante.map(itemRepresentante => (
                          <div key={item.partes_representante.indexOf(itemRepresentante)}>
                            {itemRepresentante.indexOf('(baixado)') > -1
                              ? <Typography size={14} color="neutralSecondaryAlt">{itemRepresentante}</Typography>
                              : <Typography size={14}>{itemRepresentante}</Typography>}
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Hidden>
            </div>
          )}
          <div align="right">
            {total > listaParte.length && (
              <a href="#"
                id="btnMostrarMais"
                name="btnMostrarMais"                
                onClick={loadMore}
                style={{ color: '#0078d7' }}
              >
                <AngleDown style={{ height: 14, width: 14, paddingRight: 2 }} />
                Mais
              </a>
            )}
            {lastParte && (
              <a href="#"
                id="btnMostrarMenos"
                name="btnMostrarMenos"                
                onClick={loadLess}
                style={{ color: '#0078d7' }}
              >
                <AngleUp style={{ height: 14, width: 14, paddingRight: 2 }} />
                Menos
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ParteProcesso.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchLoadMore: PropTypes.func.isRequired,
  fetchLoadLess: PropTypes.func.isRequired,
  listaParte: PropTypes.array.isRequired,
  lastParte: PropTypes.bool.isRequired,
  cdProcesso: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  listaParte: state.parteProcessoReducer.listaParte,
  lastParte: state.parteProcessoReducer.lastParte,
  cdProcesso: state.parteProcessoReducer.cdProcesso,
  total: state.parteProcessoReducer.total,
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ParteProcesso));
