import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Alert from '../alert/Alert';

import * as actions from '../alert/Alert.actions';

const Alerts = (props) => {
  const { alerts } = props;

  function onClose(alert) {
    const { closeAlertAction } = props;
    closeAlertAction(alert);
  }

  return (
    <div className="alert-wrapper">
      {alerts.map(alert => (
        <Alert
          key={alert.id}
          alert={alert}
          closeAlert={() => onClose(alert)}
        />
      ))}
    </div>
  );
};

Alerts.propTypes = {
  alerts: PropTypes.array.isRequired,
  closeAlertAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ alerts: state.alertReducer.alerts });

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
