import request from '../../utils/request';
import {
  iniciarCarregamento,
  setDadosCabecalho,
  consultarVinculos,
  consultarMovimentacoes,
  consultarDatasMovimentacoes,
  consultarPartes,
  consultarTramitacoes,
  consultarDistribuicoes,
  terminarCarregamento,
} from '../consultaProcesso/ConsultaProcesso.epics';

export const consultarProcesso = filter => (dispatch) => {
  request.all([
    dispatch(iniciarCarregamento()),
    dispatch(setDadosCabecalho(filter)),
    dispatch(consultarVinculos(filter.cdProcesso)),
    dispatch(consultarMovimentacoes(filter.cdProcesso, filter.tipoRelatorio)),
    dispatch(consultarDatasMovimentacoes(filter.cdProcesso)),
    dispatch(consultarPartes(filter.cdProcesso)),
    dispatch(consultarTramitacoes(filter.cdProcesso)),
    dispatch(consultarDistribuicoes(filter.cdProcesso)),
  ]).then(request.spread(() => {
    dispatch(terminarCarregamento());
  }));
};
