import request from '../../../../utils/request';
import { QUANTIDADE_PAGINACAO_DETALHE } from '../../../../commons/constants';
import {
  fetchVinculoProcessoAction,
  fetchVinculoProcessoActionError,
  fetchVinculoProcessoActionSuccess,
} from './VinculoProcesso.actions';

export const fetchLoadMore = (cdProcesso, page, listaVinculo) => async (dispatch) => {
  dispatch(fetchVinculoProcessoAction());
  try {
    const { data } = await request.get(`/consulta/vinculos-portaltransparencia?codigo_processo=${cdProcesso}&numero_pagina=${page}`);
    data.data.cdProcesso = cdProcesso;
    data.data.content = listaVinculo.concat(data.data.content);
    data.data.numeroPaginaVinculo = page;
    dispatch(fetchVinculoProcessoActionSuccess(data.data));
  } catch (err) {
    dispatch(fetchVinculoProcessoActionError(err.data));
  }
};

export const fetchLoadLess = (cdProcessoVinculo, listaVinculo) => async (dispatch) => {
  dispatch(fetchVinculoProcessoAction());
  try {
    const totalListaVinculo = listaVinculo.length;
    listaVinculo.splice(QUANTIDADE_PAGINACAO_DETALHE, listaVinculo.length - QUANTIDADE_PAGINACAO_DETALHE);
    const data = { cdProcesso: cdProcessoVinculo, last: false, content: listaVinculo, total_elements: totalListaVinculo, numeroPaginaVinculo: 0 };
    dispatch(fetchVinculoProcessoActionSuccess(data));
  } catch (err) {
    dispatch(fetchVinculoProcessoActionError('Houve uma falha ao tentar carregar menos vínculos!'));
  }
};
