import request from '../../utils/request';
import { montarRequisicao } from '../../utils/utils';
import {
  fetchPesquisaProcessoAction,
  fetchPesquisaProcessoActionError,
  fetchPesquisaProcessoActionSuccess,
} from './PesquisaProcesso.actions';

export const fetchPesquisaProcesso = params => async (dispatch) => {
  dispatch(fetchPesquisaProcessoAction());
  try {
    const req = montarRequisicao(params);
    const { data } = await request.post(req.url, req.body);

    data.data.cdOrgao = params.cdOrgao;
    data.data.numeroMP = params.numeroMP;
    data.data.cdSituacao = params.cdSituacao;
    data.data.tipoRelatorio = params.tipoRelatorio;
    data.data.parteInteressada = params.parteInteressada;
    data.data.dataInicial = params.dataInicial;
    data.data.dataFinal = params.dataFinal;
    data.data.tipoProcesso = params.tipoProcesso;
    dispatch(fetchPesquisaProcessoActionSuccess(data.data));
  } catch (error) {
    error.data = { tipoRelatorio: params.tipoRelatorio, tipoProcesso: params.tipoProcesso };
    dispatch(fetchPesquisaProcessoActionError(error.data));
  }
};

export const fetchConfiguracoes = () => async () => {
  const { data } = await request.get('/configuracoes');
  localStorage.setItem('configuracoes', JSON.stringify(data));
};
