import { createReducer } from '../../commons/redux/createReducer';
import initialState from './MovimentacaoUnidade.initialState';
import * as actions from './MovimentacaoUnidade.actions';

const fetchMovimentacaoUnidade = state => ({
  ...state,
  loading: true,
});

const fetchMovimentacaoUnidadeSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  base64: action.data,
});

const fetchMovimentacaoUnidadeError = (state, action) => ({
  ...state,
  loading: false,
  error: true,
  msgErro: action.error,
});

const fetchMovimentacaoUnidadeAnoMesInicialSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  anoMesInicial: action.data.anoMesInicial,
});

const fetchMovimentacaoUnidadeAnoMesInicialError = (state, action) => ({
  ...state,
  error: true,
  loading: false,
  msgErro: action.error,
});

const ConsultaProcessoReducer = createReducer(initialState, {
  [actions.fetchMovimentacaoUnidadeAction().type]: fetchMovimentacaoUnidade,
  [actions.fetchMovimentacaoUnidadeActionError().type]: fetchMovimentacaoUnidadeError,
  [actions.fetchMovimentacaoUnidadeActionSuccess().type]: fetchMovimentacaoUnidadeSuccess,
  [actions.fetchMovimentacaoUnidadeActionAnoMesInicialError().type]: fetchMovimentacaoUnidadeAnoMesInicialError,
  [actions.fetchMovimentacaoUnidadeActioAnoMesInicialSuccess().type]: fetchMovimentacaoUnidadeAnoMesInicialSuccess,
});

export default ConsultaProcessoReducer;
