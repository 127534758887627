export const fetchConsultaProcessoAction = () => ({ type: 'FETCH_CONSULTAPROCESSO' });
export const fetchConsultaProcessoActionRenderSucess = () => ({ type: 'FETCH_CONSULTAPROCESSORENDER_SUCESS' });
export const fetchConsultaProcessoActionSuccess = processo => ({ type: 'FETCH_CONSULTAPROCESSO_SUCCESS', processo });
export const fetchConsultaProcessoActionError = error => ({ type: 'FETCH_CONSULTAPROCESSO_ERROR', error });
export const fetchConsultaProcessoAtualizaPaginaAtualActionSuccess = paginaAtual => ({ type: 'FETCH_CONSULTAPROCESSO_ATUALIZAPAGINA_SUCCESS', paginaAtual });
export const fetchVinculoProcessoActionSuccess = data => ({ type: 'FETCH_VINCULOPROCESSO_SUCCESS', data });
export const fetchVinculoProcessoActionError = error => ({ type: 'FETCH_VINCULOPROCESSO_ERROR', error });
export const fetchArquivoConsultaProcessoActionSuccess = arquivo => ({ type: 'FETCH_ARQUIVO_CONSULTAPROCESSO_SUCCESS', arquivo });
export const fetchArquivoConsultaProcessoActionError = error => ({ type: 'FETCH_ARQUIVO_CONSULTAPROCESSO_ERROR', error });

export const fetchPopperisOpenAction = () => ({ type: 'FETCH_POPPER_ISOPEN_SUCESS' });
export const fetchPopperFecharTodosAction = () => ({ type: 'FETCH_POPPER_FECHARTODOS_SUCESS' });
