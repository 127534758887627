/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import dayjs from 'dayjs';
import messages from './messages';

export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((msgs, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      msgs[prefixedKey] = value;
    } else {
      Object.assign(msgs, flattenMessages(value, prefixedKey));
    }

    return msgs;
  }, {});
}

function updateLocale(locale) {
  if (locale === 'pt') {
    require('dayjs/locale/pt-br');
    dayjs.locale('pt-br');
    require('moment/locale/pt');
    moment.updateLocale('pt', {
      weekdaysMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      week: { dow: 0 },
    });
  } else if (locale === 'es') {
    require('dayjs/locale/es');
    dayjs.locale('es');
    require('moment/locale/es');
    moment.updateLocale('es', { week: { dow: 0 } });
  }
}

export function messagesFromLanguage(language) {
  const splitLanguage = language.split('-');
  const locale = splitLanguage[0];
  updateLocale(locale);
  return Object.keys(messages).find((message) => {
    if (message === language) {
      return message;
    }
    const splitMessage = message.split('-');
    if (splitLanguage && splitMessage && splitLanguage[0] === splitMessage[0]) {
      return message;
    }
    return null;
  });
}

export function localeBuilder(languages) {
  if (languages) {
    return languages.reduce(language => messagesFromLanguage(language));
  }
  return 'pt-BR';
}
