import * as actions from './VinculoProcesso.actions';
import initialState from './VinculoProcesso.initialState';
import { createReducer } from '../../../../commons/redux/createReducer';

const fetchVinculoProcesso = state => ({
  ...state,
  loading: true,
});

const fetchVinculoProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  lastVinculo: action.data.last,
  listaVinculo: action.data.content,
  cdProcesso: action.data.cdProcesso,
  totalElements: action.data.total_elements,
  numeroPaginaVinculo: action.data.numeroPaginaVinculo,
});

const fetchVinculoProcessoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
  listaVinculo: [],
  totalElements: 0,
});

const VinculoProcessoReducer = createReducer(initialState, {
  [actions.fetchVinculoProcessoAction().type]: fetchVinculoProcesso,
  [actions.fetchVinculoProcessoActionSuccess().type]: fetchVinculoProcessoSuccess,
  [actions.fetchVinculoProcessoActionError().type]: fetchVinculoProcessoError,
});

export default VinculoProcessoReducer;
