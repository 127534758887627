import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AngleDown from '@bayon/svg-icons/icons/AngleDown';
import AngleUp from '@bayon/svg-icons/icons/AngleUp';
import { Typography } from '@bayon/commons';
import moment from 'moment';
import * as actions from './TramitacaoProcesso.epics';
import styles from '../../commons/styles';

function TramitacaoProcesso(props) {
  const { classes, fetchLoadMoreTramitacoes, fetchLoadLessTramitacoes, listaTramitacao, lastTramitacao, cdProcesso, totalElements, numeroPaginaTramitacao } = props;

  function carregarMaisTramitacoes(e) {
    e.preventDefault();
    fetchLoadMoreTramitacoes(cdProcesso, numeroPaginaTramitacao + 1, listaTramitacao);
  }

  function carregarMenosTramitacoes(e) {
    e.preventDefault();
    fetchLoadLessTramitacoes(cdProcesso, listaTramitacao);
  }

  return (
    <div className={classes.root}>
      <div className={classes.colorPadding}>
        <div className={classes.colorPaddingInterna}>
          <div className={classes.fontCabecalhoCard}>Tramitações</div>
          {!listaTramitacao.length > 0 && (
            <div>
              <br />
              <div><Typography size={14} color="neutralTertiary">Não existem tramitações para este cadastro.</Typography></div>
            </div>
          )}
          {listaTramitacao.length > 0 && (
            <div>
              <Hidden smDown>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tablecell}>Guia</TableCell>
                      <TableCell className={classes.tablecell}>Data de criação</TableCell>
                      <TableCell className={classes.tablecell}>Origem</TableCell>
                      <TableCell className={classes.tablecell}>Destino</TableCell>
                      <TableCell className={classes.tablecell}>Data de entrega</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaTramitacao.map(item => (
                      <TableRow key={item.numero_sequencial}>
                        <TableCell className={classes.tablecell}>{item.numero_lote}</TableCell>
                        <TableCell className={classes.tablecell}>{moment(item.data_remessa).format('DD/MM/YYYY')}</TableCell>
                        <TableCell className={classes.tablecell}>{item.local_origem}</TableCell>
                        <TableCell className={classes.tablecell}>{item.local_destino}</TableCell>
                        <TableCell className={classes.tablecell}>{moment(item.data_recebimento).format('DD/MM/YYYY')}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.rootCard}>
                  {listaTramitacao.map(item => (
                    <Grid container key={item.numero_sequencial} className={classes.li}>
                      <Grid item xs={12} sm={3} className={classes.col}>
                        <div className={classes.unjLabel}> Guia </div>
                        <div>{item.numero_lote}</div>
                      </Grid>
                      <Grid item xs={12} sm={3} className={classes.col}>
                        <div className={classes.unjLabel}> Data de criação </div>
                        <div>{moment(item.data_remessa).format('DD/MM/YYYY')}</div>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.col}>
                        <div className={classes.unjLabel}> Origem </div>
                        <div>{item.local_origem}</div>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.col}>
                        <div className={classes.unjLabel}> Destino </div>
                        <div>{item.local_destino}</div>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.col}>
                        <div className={classes.unjLabel}> Data de entrega </div>
                        <div>{moment(item.data_recebimento).format('DD/MM/YYYY')}</div>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Hidden>
            </div>
          )}
          <div style={{ float: 'right', position: 'relative', marginLeft: 10 }}>
            {!lastTramitacao && totalElements > 5 && (
              <a href="#"
                id="btnMostrarMais"
                name="btnMostrarMais"                
                onClick={carregarMaisTramitacoes}
                style={{ color: '#0078d7' }}
              >
                <AngleDown style={{ height: 14, width: 14, paddingRight: 2 }} />
                Mais
              </a>
            )}
          </div>
          <div style={{ float: 'right', position: 'relative' }}>
            {numeroPaginaTramitacao > 0 && totalElements > 5 && (
              <a href="#"
                id="btnMostrarMenos"
                name="btnMostrarMenos"                
                onClick={carregarMenosTramitacoes}
                style={{ color: '#0078d7' }}
              >
                <AngleUp style={{ height: 14, width: 14, paddingRight: 2 }} />
                Menos
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

TramitacaoProcesso.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchLoadMoreTramitacoes: PropTypes.func.isRequired,
  fetchLoadLessTramitacoes: PropTypes.func.isRequired,
  lastTramitacao: PropTypes.bool.isRequired,
  totalElements: PropTypes.number.isRequired,
  listaTramitacao: PropTypes.array.isRequired,
  cdProcesso: PropTypes.string.isRequired,
  numeroPaginaTramitacao: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  lastTramitacao: state.tramitacaoProcessoReducer.lastTramitacao,
  listaTramitacao: state.tramitacaoProcessoReducer.listaTramitacao,
  cdProcesso: state.tramitacaoProcessoReducer.cdProcesso,
  totalElements: state.tramitacaoProcessoReducer.totalElements,
  numeroPaginaTramitacao: state.tramitacaoProcessoReducer.numeroPaginaTramitacao,
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TramitacaoProcesso));
