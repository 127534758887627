import * as actions from './ParteProcesso.actions';
import initialState from './ParteProcesso.initialState';
import { createReducer } from '../../commons/redux/createReducer';

const fetchParteProcesso = state => ({
  ...state,
  loading: true,
});

const fetchParteProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  listaParte: action.data.partes,
  lastParte: action.data.lastParte,
  cdProcesso: action.data.cdProcesso,
  total: action.data.total,
});

const fetchParteProcessoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
  listaParte: [],
  lastParte: false,
  total: 0,
});

const ParteProcessoReducer = createReducer(initialState, {
  [actions.fetchParteProcessoAction().type]: fetchParteProcesso,
  [actions.fetchParteProcessoActionSuccess().type]: fetchParteProcessoSuccess,
  [actions.fetchParteProcessoActionError().type]: fetchParteProcessoError,
});

export default ParteProcessoReducer;
