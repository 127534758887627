export function getHabilitaCaptcha() {
  if (!localStorage.getItem('configuracoes')) {
    return false;
  }
  return JSON.parse(localStorage.getItem('configuracoes')).habilitaCaptcha;
}
export function getExibirFiltros() {
  if (!localStorage.getItem('configuracoes')) {
    return false;
  }
  return JSON.parse(localStorage.getItem('configuracoes')).exibirFiltros;
}
export function getHabilitaMembroRespDataVista() {
  if (!localStorage.getItem('configuracoes')) {
    return 0;
  }
  return JSON.parse(localStorage.getItem('configuracoes')).habilitaMembroRespDataVista;
}
