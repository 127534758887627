export const initialState = {
  error: false,
  loading: false,
  // last: false,
  // listaTramitacao: [],
  // listaDistribuicao: [],
  // lastDistribuicao: false,
};

export default initialState;
