export const initialState = {
  error: false,
  loading: false,
  listaTramitacao: [],
  lastTramitacao: false,
  cdProcesso: '',
  totalElements: 0,
  numeroPaginaTramitacao: 0,
};

export default initialState;
