import request from '../../utils/request';
import { fetchSituacaoAction, fetchSituacaoActionError, fetchSituacaoActionSuccess } from './Situacao.actions';

export const fetchSituacao = () => async (dispatch) => {
  dispatch(fetchSituacaoAction());
  try {
    const { data } = await request.get('/consulta/situacoes-portaltransparencia');
    data.data.unshift({ codigo: '', descricao: 'Selecione uma situação' });
    dispatch(fetchSituacaoActionSuccess(data.data));
  } catch (err) {
    dispatch(fetchSituacaoActionError(err.data));
  }
};
