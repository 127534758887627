import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from '@bayon/commons';

import * as actions from './Situacao.epics';

const Situacao = function Situacao({ loading, situacao, fetchSituacao, ...restProps }) {
  useEffect(() => {
    fetchSituacao();
  }, []);

  if (loading) return false;

  return (
    <Select
      size="medium"
      options={situacao.map(item => ({ value: item.codigo, label: item.descricao }))}
      {...restProps}
    />
  );
};

Situacao.propTypes = {
  loading: PropTypes.bool.isRequired,
  situacao: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchSituacao: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loading: state.situacaoReducer.loading,
  situacao: state.situacaoReducer.situacao,
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(Situacao);
