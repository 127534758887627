import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AngleDown from '@bayon/svg-icons/icons/AngleDown';
import AngleUp from '@bayon/svg-icons/icons/AngleUp';
import { Typography } from '@bayon/commons';
import classNames from 'classnames';
import { formatarNumeroMP } from '../../../../commons/mascaras';
import * as actions from './VinculoProcesso.epics';
import styles from '../../../../commons/styles';
import * as actionConsultaProcessoCommon from '../../../common/ConsultaProcessoCommon';

function VinculoProcesso(props) {
  const { classes, fetchLoadMore, fetchLoadLess, lastVinculo, listaVinculo, cdProcesso, totalElements, 
    numeroPaginaVinculo } = props;
  
  function loadMore(e) {
    e.preventDefault();
    fetchLoadMore(cdProcesso, numeroPaginaVinculo + 1, listaVinculo);
  }

  function loadLess(e) {
    e.preventDefault();
    fetchLoadLess(cdProcesso, listaVinculo);
  }

  return (
    <div className={classes.root}>
      <div className={classes.colorPadding}>
        <div className={classes.colorPaddingInterna}>
          <div className={classes.fontCabecalhoCard}>Processos vinculados</div>
          {!listaVinculo.length > 0 && (
            <div>
              <br />
              <div><Typography size={14} color="neutralTertiary">Não existem vínculos para este cadastro.</Typography></div>
            </div>
          )}
          {listaVinculo.length > 0 && (
            <div>
              <Hidden smDown>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classNames(classes.tablecell, classes.larguraColuna16)}>Processo</TableCell>
                      <TableCell className={classNames(classes.tablecell, classes.larguraColuna28)}>Tipo de vínculo</TableCell>
                      <TableCell className={classNames(classes.tablecell, classes.larguraColuna28)}>Tipo de cadastro</TableCell>
                      <TableCell className={classNames(classes.tablecell, classes.larguraColuna28)}>Local</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaVinculo.map(item => (
                      <TableRow key={item.numero_processo}>
                        <TableCell className={classes.tablecell}>
                          {formatarNumeroMP(item.numero_processo)}
                        </TableCell>
                        <TableCell className={classes.tablecell}>{item.tipo_vinculo}</TableCell>
                        <TableCell className={classes.tablecell}>{item.tipo_processo}</TableCell>
                        <TableCell className={classes.tablecell}>{item.local}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.rootCard}>
                  {listaVinculo.map(item => (
                    <Grid container key={item.numero_processo} className={classes.li}>
                      <Grid item xs={12} sm={3} className={classes.col}>
                        <div className={classes.unjLabel}> Processo </div>                        
                        <div>{formatarNumeroMP(item.numero_processo)}</div>
                      </Grid>
                      <Grid item xs={12} sm={3} className={classes.col}>
                        <div className={classes.unjLabel}> Tipo de vínculo </div>
                        <div>{item.tipo_vinculo}</div>
                      </Grid>
                      <Grid item xs={12} sm={3} className={classes.col}>
                        <div className={classes.unjLabel}> Tipo de cadastro </div>
                        <div>{item.tipo_processo}</div>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.col}>
                        <div className={classes.unjLabel}> Local </div>
                        <div>{item.local}</div>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Hidden>
            </div>
          )}
          <div style={{ float: 'right', position: 'relative', marginLeft: 10 }}>
            {!lastVinculo && totalElements > 5 && (
              <a href="#"
                id="btnMostrarMais"
                name="btnMostrarMais"
                onClick={loadMore}
                style={{ color: '#0078d7' }}
              >
                <AngleDown style={{ height: 14, width: 14, paddingRight: 2 }} />
                Mais
              </a>
            )}
          </div>
          <div style={{ float: 'right', position: 'relative' }}>
            {numeroPaginaVinculo > 0 && totalElements > 5 && (
            <a href="#"
              id="btnMostrarMenos"
              name="btnMostrarMenos"
              onClick={loadLess}
              style={{ color: '#0078d7' }}
            >
              <AngleUp style={{ height: 14, width: 14, paddingRight: 2 }} />
                Menos
            </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

VinculoProcesso.propTypes = { classes: PropTypes.object.isRequired };

VinculoProcesso.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchLoadMore: PropTypes.func.isRequired,
  fetchLoadLess: PropTypes.func.isRequired,
  lastVinculo: PropTypes.bool.isRequired,
  listaVinculo: PropTypes.array.isRequired,
  cdProcesso: PropTypes.string.isRequired,
  totalElements: PropTypes.number.isRequired,
  consultarProcesso: PropTypes.func.isRequired,
  tipoRelatorio: PropTypes.number.isRequired,
  numeroPaginaVinculo: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  lastVinculo: state.vinculoProcessoReducer.lastVinculo,
  listaVinculo: state.vinculoProcessoReducer.listaVinculo,
  cdProcesso: state.vinculoProcessoReducer.cdProcesso,
  totalElements: state.vinculoProcessoReducer.totalElements,
  tipoRelatorio: state.pesquisaProcessoReducer.tipoRelatorio,  
  numeroPaginaVinculo: state.vinculoProcessoReducer.numeroPaginaVinculo,
});

const mapDispatchToProps = { ...actions, ...actionConsultaProcessoCommon };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VinculoProcesso));
