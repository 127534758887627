import { typographyConstants } from '@bayon/commons';

const styles = () => ({
  colorPadding: {
    paddingTop: 25,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: '#F2F2F2',
  },

  colorPaddingDistribuicao: {
    padding: 25,
    backgroundColor: '#F2F2F2',
  },

  colorPaddingInterna: {
    padding: 20,
    minHeight: 100,
    backgroundColor: '#FFFFFF',
  },

  root: {
    marginLeft: -15,
    marginRight: -15,
  },

  tablecell: {
    padding: 10,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: typographyConstants.fontFamily,
  },

  rootCard: {
    flexGrow: 1,
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  li: {
    padding: 15,
    backgroundColor: '#F2F2F2',
    marginBottom: 15,
  },

  col: { padding: 5 },

  unjLabel: {
    marginBottom: 0,
    fontSize: 12,
    color: '#919191',
    lineHeight: '20px',
  },

  fontCabecalhoCard: { fontWeight: 600, fontSize: 16 },

  quadroMovimentacao: {
    padding: 10,
    backgroundColor: '#F2F2F2',
  },

  larguraColuna16: { width: '16%' },
  larguraColuna28: { width: '28%' },
});

export default styles;
