import React, { useEffect } from 'react';
import { Grid, Loader } from '@bayon/commons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CabecalhoProcesso from './components/cabecalhoProcesso/CabecalhoProcesso';
import VinculoProcesso from './components/vinculoProcesso/VinculoProcesso';
import MovimentacaoProcesso from '../../components/movimentacaoProcesso/MovimentacaoProcesso';
import ParteProcesso from '../../components/parteProcesso/ParteProcesso';
import TramitacaoProcesso from '../../components/tramitacaoProcesso/TramitacaoProcesso';
import DistribuicaoProcesso from '../../components/distribuicaoProcesso/DistribuicaoProcesso';

function DetalheProcesso(props) {
  const { history, loadingConsulta } = props;
  useEffect(() => {
    window.parent.scroll(0, 0);
  }, []);

  if (loadingConsulta) {
    return <div style={{ position: 'absolute', left: '50%', top: '50%' }}><Loader size="large" color="blue" label="Carregando" /></div>;
  }

  return (
    <Grid container>
      <div className="container-fluid">
        <CabecalhoProcesso history={history} />
        <VinculoProcesso />
        <MovimentacaoProcesso />
        <ParteProcesso />
        <TramitacaoProcesso />
        <DistribuicaoProcesso />
      </div>
    </Grid>
  );
}

DetalheProcesso.propTypes = { history: PropTypes.object.isRequired, loadingConsulta: PropTypes.bool.isRequired };

const mapStateToProps = state => ({ loadingConsulta: state.consultaProcessoReducer.loadingConsulta ? state.consultaProcessoReducer.loadingConsulta : false });

export default connect(mapStateToProps)(DetalheProcesso);
