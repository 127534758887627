export const initialState = {
  error: false,
  loading: false,
  listaDistribuicao: [],
  lastDistribuicao: false,
  cdProcesso: '',
  totalElements: 0,
  numeroPaginaDistribuicao: 0,
};

export default initialState;
