import { createReducer } from '../../commons/redux/createReducer';
import initialState from './Situacao.initialState';
import * as actions from './Situacao.actions';

const fetchSituacao = state => ({
  ...state,
  loading: true,
});

const fetchSituacaoSuccess = (state, action) => ({
  ...state,
  loading: false,
  situacao: action.data,
  error: false,
});

const fetchSituacaoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const SituacaoReducer = createReducer(initialState, {
  [actions.fetchSituacaoAction().type]: fetchSituacao,
  [actions.fetchSituacaoActionSuccess().type]: fetchSituacaoSuccess,
  [actions.fetchSituacaoActionError().type]: fetchSituacaoError,
});

export default SituacaoReducer;
