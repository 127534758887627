import request from '../../utils/request';
import { montarRequisicao, TipoRelatorioEnum } from '../../utils/utils';
import { fetchMovimentacaoProcessoActionSuccess, fetchMovimentacaoProcessoActionError, fetchDatasMovimentacaoProcessoActionSuccess, fetchDatasMovimentacaoProcessoActionError } from '../../components/movimentacaoProcesso/MovimentacaoProcesso.actions';
import { fetchParteProcessoActionSuccess, fetchParteProcessoActionError } from '../../components/parteProcesso/ParteProcesso.actions';
import { fetchTramitacaoProcessoActionSuccess, fetchTramitacaoProcessoActionError } from '../../components/tramitacaoProcesso/TramitacaoProcesso.actions';
import { fetchDistribuicaoProcessoActionSuccess, fetchDistribuicaoProcessoActionError } from '../../components/distribuicaoProcesso/DistribuicaoProcesso.actions';
import { fetchPesquisaProcessoAction, fetchPesquisaProcessoActionError, fetchPesquisaProcessoActionSuccess } from '../pesquisaProcesso/PesquisaProcesso.actions';
import { fetchConsultaProcessoAction, fetchConsultaProcessoActionRenderSucess, fetchConsultaProcessoActionSuccess, fetchConsultaProcessoAtualizaPaginaAtualActionSuccess, fetchVinculoProcessoActionSuccess, fetchVinculoProcessoActionError, fetchArquivoConsultaProcessoActionSuccess, fetchArquivoConsultaProcessoActionError, fetchPopperisOpenAction, fetchPopperFecharTodosAction } from './ConsultaProcesso.actions';

export const consultarVinculos = cdProcesso => async (dispatch) => {
  try {
    const { data } = await request.get(`/consulta/vinculos-portaltransparencia?codigo_processo=${cdProcesso}`);
    data.data.cdProcesso = cdProcesso;
    data.data.numeroPaginaVinculo = 0;
    dispatch(fetchVinculoProcessoActionSuccess(data.data));
  } catch (error) {
    dispatch(fetchVinculoProcessoActionError(error.data));
  }
};

export const consultarMovimentacoes = (cdProcesso, tipoRelatorio) => async (dispatch) => {
  try {
    const apenasMovimentacaoParametro = tipoRelatorio === TipoRelatorioEnum.TAC
      || tipoRelatorio === TipoRelatorioEnum.RECOMENDACAO
      || tipoRelatorio === TipoRelatorioEnum.AUDIENCIAS_PUBLICAS_RESOLUCAO_92;
    const { data } = await request.get(`/consulta/movimentacoes-portaltransparencia?tipo_relatorio=${tipoRelatorio}&codigo_processo=${cdProcesso}&apenas_movimentacao_parametro=${apenasMovimentacaoParametro}`);
    data.data.cdProcesso = cdProcesso;
    data.data.numeroPaginaMovimentacao = 0;
    dispatch(fetchMovimentacaoProcessoActionSuccess(data.data));
  } catch (error) {
    dispatch(fetchMovimentacaoProcessoActionError(error.data));
  }
};

export const consultarDatasMovimentacoes = cdProcesso => async (dispatch) => {
  try {
    const { data } = await request.get(`/consulta/datas-principais-movimentacoes-portaltransparencia?codigo_processo=${cdProcesso}`);
    dispatch(fetchDatasMovimentacaoProcessoActionSuccess(data.data));
  } catch (error) {
    dispatch(fetchDatasMovimentacaoProcessoActionError(error.data));
  }
};

export const consultarPartes = cdProcesso => async (dispatch) => {
  try {
    const { data } = await request.get(`/consulta/partes-portaltransparencia?codigo_processo=${cdProcesso}&somente_antigas=true`);
    data.data.lastParte = false;
    data.data.cdProcesso = cdProcesso;
    dispatch(fetchParteProcessoActionSuccess(data.data));
  } catch (error) {
    dispatch(fetchParteProcessoActionError(error.data));
  }
};

export const consultarTramitacoes = cdProcesso => async (dispatch) => {
  try {
    const { data } = await request.get(`/consulta/tramitacoes-portaltransparencia?codigo_processo=${cdProcesso}`);
    data.data.cdProcesso = cdProcesso;
    data.data.numeroPaginaTramitacao = 0;
    dispatch(fetchTramitacaoProcessoActionSuccess(data.data));
  } catch (error) {
    dispatch(fetchTramitacaoProcessoActionError(error.data));
  }
};

export const consultarDistribuicoes = cdProcesso => async (dispatch) => {
  try {
    const { data } = await request.get(`/consulta/distribuicoes-portaltransparencia?codigo_processo=${cdProcesso}`);
    data.data.cdProcesso = cdProcesso;
    data.data.numeroPaginaDistribuicao = 0;
    dispatch(fetchDistribuicaoProcessoActionSuccess(data.data));
  } catch (error) {
    dispatch(fetchDistribuicaoProcessoActionError(error.data));
  }
};

export const iniciarCarregamento = () => async (dispatch) => {
  dispatch(fetchConsultaProcessoAction());
};

export const terminarCarregamento = () => async (dispatch) => {
  dispatch(fetchConsultaProcessoActionRenderSucess());
};

export const setDadosCabecalho = processo => async (dispatch) => {
  if (processo.tipoRelatorio && processo.nuProcesso) {
    const req = montarRequisicao({ tipoRelatorio: processo.tipoRelatorio, nuProcesso: processo.nuProcesso });
    const { data } = await request.post(req.url, req.body);
    dispatch(fetchConsultaProcessoActionSuccess(data.data.content[0]));
  } else {
    dispatch(fetchConsultaProcessoActionSuccess(processo));
  }
};

export const setPaginaAtual = paginaAtual => (dispatch) => {
  dispatch(fetchConsultaProcessoAtualizaPaginaAtualActionSuccess(paginaAtual));
};

export const fetchChangePage = params => async (dispatch) => {
  dispatch(fetchPesquisaProcessoAction());
  try {
    const req = montarRequisicao(params);
    req.body.numero_pagina = params.pagina;
    const { data } = await request.post(req.url, req.body);

    data.data.cdOrgao = params.cdOrgao;
    data.data.numeroMP = params.numeroMP;
    data.data.cdSituacao = params.cdSituacao;
    data.data.tipoRelatorio = params.tipoRelatorio;
    data.data.parteInteressada = params.parteInteressada;
    data.data.dataInicial = params.dataInicial;
    data.data.dataFinal = params.dataFinal;
    data.data.tipoProcesso = params.tipoProcesso;
    dispatch(fetchPesquisaProcessoActionSuccess(data.data));
  } catch (error) {
    dispatch(fetchPesquisaProcessoActionError(error.data));
  }
};

export const fetchArquivo = params => async (dispatch) => {
  let arquivo = '';
  dispatch(fetchConsultaProcessoAction());
  try {
    const req = montarRequisicao(params);
    req.body.numero_pagina = params.pagina;
    const endPointDownload = [TipoRelatorioEnum.TAC_2011, TipoRelatorioEnum.INQUERITO_CIVIL_2011].includes(params.tipoRelatorio) ? '/downloadFileResolucao22011' : '/downloadFile';
    const { data } = await request.post(`${req.url}${endPointDownload}`, req.body);
    dispatch(fetchArquivoConsultaProcessoActionSuccess(data));
    arquivo = data;
  } catch (error) {
    dispatch(fetchArquivoConsultaProcessoActionError(error));
  }
  return arquivo;
};

export const setPopperIsOpen = () => async (dispatch) => {
  dispatch(fetchPopperisOpenAction());
};

export const setFecharTodos = () => async (dispatch) => {
  dispatch(fetchPopperFecharTodosAction());
};
