import * as actions from './TramitacaoProcesso.actions';
import initialState from './TramitacaoProcesso.initialState';
import { createReducer } from '../../commons/redux/createReducer';

const fetchTramitacaoProcesso = state => ({
  ...state,
  loading: true,
});

const fetchTramitacaoProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  lastTramitacao: action.data.last,
  listaTramitacao: action.data.content,
  cdProcesso: action.data.cdProcesso,
  totalElements: action.data.total_elements,
  numeroPaginaTramitacao: action.data.numeroPaginaTramitacao,
});

const fetchTramitacaoProcessoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
  listaTramitacao: [],
  totalElements: 0,
});

const TramitacaoProcessoReducer = createReducer(initialState, {
  [actions.fetchTramitacaoProcessoAction().type]: fetchTramitacaoProcesso,
  [actions.fetchTramitacaoProcessoActionSuccess().type]: fetchTramitacaoProcessoSuccess,
  [actions.fetchTramitacaoProcessoActionError().type]: fetchTramitacaoProcessoError,
});

export default TramitacaoProcessoReducer;
