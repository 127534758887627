export const initialState = {
  error: false,
  loading: false,
  listaMovimentacao: [],
  lastMovimentacao: false,
  cdProcesso: '',
  totalElements: 0,
  dtInstauracao: '',
  dtArquivamento: '',
  dtHomologacao: '',
  numeroPaginaMovimentacao: 0,
};

export default initialState;
