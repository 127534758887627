import request from '../../utils/request';
import { QUANTIDADE_PAGINACAO_DETALHE } from '../../commons/constants';
import {
  fetchMovimentacaoProcessoAction,
  fetchMovimentacaoProcessoActionError,
  fetchMovimentacaoProcessoActionSuccess,
} from './MovimentacaoProcesso.actions';

export const fetchLoadMore = (cdProcesso, page, listaMovimentacao, tipoRelatorio, apenasMovimentacaoParametro) => async (dispatch) => {
  dispatch(fetchMovimentacaoProcessoAction());
  try {
    const { data } = await request.get(`/consulta/movimentacoes-portaltransparencia?tipo_relatorio=${tipoRelatorio}&codigo_processo=${cdProcesso}&numero_pagina=${page}&apenas_movimentacao_parametro=${apenasMovimentacaoParametro}`);
    data.data.cdProcesso = cdProcesso;
    data.data.content = listaMovimentacao.concat(data.data.content);
    data.data.numeroPaginaMovimentacao = page;
    dispatch(fetchMovimentacaoProcessoActionSuccess(data.data));
  } catch (err) {
    dispatch(fetchMovimentacaoProcessoActionError(err.data));
  }
};

export const fetchLoadLess = (cdProcessoMovimentacao, listaMovimentacao) => async (dispatch) => {
  dispatch(fetchMovimentacaoProcessoAction());
  try {
    const totalListaMovimentacao = listaMovimentacao.length;
    listaMovimentacao.splice(QUANTIDADE_PAGINACAO_DETALHE, listaMovimentacao.length - QUANTIDADE_PAGINACAO_DETALHE);
    const data = { cdProcesso: cdProcessoMovimentacao, last: false, content: listaMovimentacao, total_elements: totalListaMovimentacao, numeroPaginaMovimentacao: 0 };
    dispatch(fetchMovimentacaoProcessoActionSuccess(data));
  } catch (err) {
    dispatch(fetchMovimentacaoProcessoActionError('Houve uma falha ao tentar carregar menos movimentações!'));
  }
};

export const consultarMovimentacoes = (cdProcesso, tipoRelatorio, apenasMovimentacaoParametro) => async (dispatch) => {
  try {
    const { data } = await request.get(`/consulta/movimentacoes-portaltransparencia?tipo_relatorio=${tipoRelatorio}&codigo_processo=${cdProcesso}&apenas_movimentacao_parametro=${apenasMovimentacaoParametro}`);
    data.data.cdProcesso = cdProcesso;
    data.data.numeroPaginaMovimentacao = 0;
    dispatch(fetchMovimentacaoProcessoActionSuccess(data.data));
  } catch (error) {
    dispatch(fetchMovimentacaoProcessoActionError(error.data));
  }
};
