import moment from 'moment';

export const TipoRelatorioEnum = {
  PROCEDIMENTO_PREPARATORIO: 1,
  INQUERITO_CIVIL: 2,
  PROCEDIMENTO_INVESTIGATORIO: 3,
  INQUERITO_POLICIAL: 4,
  TAC: 5,
  RECOMENDACAO: 6,
  PROCESSO_JUDICIAL_EXTRAJUDICIAL: 7,
  TAC_2011: 8,
  INQUERITO_CIVIL_2011: 9,
  CONSULTA_DISTRIBUICAO_110_2014: 10,
  AUDIENCIAS_PUBLICAS_RESOLUCAO_92: 11,
};

export const TipoProcessoEnum = {
  INQUERITO_POLICIAL: 1,
  PROCESSO_JUDICIAL: 2,
  PROCESSO_EXTRA_JUDICIAL: 3,
};

export const tiposProcessos = [
  { value: TipoProcessoEnum.INQUERITO_POLICIAL, label: 'Inquéritos Policiais' },
  { value: TipoProcessoEnum.PROCESSO_JUDICIAL, label: 'Processos Judiciais' },
  { value: TipoProcessoEnum.PROCESSO_EXTRA_JUDICIAL, label: 'Processos Extrajudiciais' },
];

function getURLBASE(tipoRelatorio) {
  const tiposRelatorioResolucao = [TipoRelatorioEnum.INQUERITO_CIVIL_2011, TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014];
  if (tipoRelatorio <= 4 || tiposRelatorioResolucao.includes(tipoRelatorio)) {
    return '/consulta/processos-portaltransparencia';
  }
  return '/consulta/processosmov-portaltransparencia';
}

function getTipoProcesso(body, tipoProcesso) {
  const res = body;
  if (tipoProcesso) {
    res.tipo_processo = tipoProcesso - 1;
  }
  return res;
}

export function montarRequisicao(params) {
  const objReq = { url: getURLBASE(params.tipoRelatorio) };

  objReq.body = { tipo_relatorio: params.tipoRelatorio };
  if (params.numeroMP) { objReq.body.numero_processo = params.numeroMP; }
  if (params.nuProcesso) { objReq.body.numero_processo = params.nuProcesso; }
  if (params.cdOrgao) { objReq.body.codigo_orgao = params.cdOrgao; }
  if (params.cdSituacao) { objReq.body.lista_situacoes = [params.cdSituacao]; }
  if (params.parteInteressada) { objReq.body.parte_interessada = params.parteInteressada; }
  if (params.dataInicial) { objReq.body.data_inicial = params.dataInicial; }
  if (params.dataFinal) { objReq.body.data_final = params.dataFinal; }
  objReq.body.url_relatorio = objReq.url;
  objReq.body = getTipoProcesso(objReq.body, params.tipoProcesso);

  return objReq;
}

export function getAnos(anoMesInicial) {
  const anoArray = [];
  const anoAtual = new Date().getFullYear();
  for (let i = parseInt(anoMesInicial, 10); i <= anoAtual; i += 1) {
    anoArray.unshift({ value: i, label: i });
  }
  return anoArray;
}

export function getMeses(ano, anoMesInicial) {
  const dataAtual = new Date();
  const mesInicial = parseInt(anoMesInicial.substring(4), 10);
  const anoInicial = parseInt(anoMesInicial.substring(0, 4), 10);
  const mesesArray = [
    { value: '1', label: 'Janeiro' },
    { value: '2', label: 'Fevereiro' },
    { value: '3', label: 'Março' },
    { value: '4', label: 'Abril' },
    { value: '5', label: 'Maio' },
    { value: '6', label: 'Junho' },
    { value: '7', label: 'Julho' },
    { value: '8', label: 'Agosto' },
    { value: '9', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  if (ano === anoInicial && ano !== dataAtual.getFullYear()) {
    mesesArray.splice(0, mesInicial - 1);
  } else if (ano === dataAtual.getFullYear()) {
    if (anoInicial !== dataAtual.getFullYear()) {
      mesesArray.splice(dataAtual.getMonth(), mesesArray.length);
    } else {
      mesesArray.splice(0, mesInicial - 1);
      mesesArray.splice(dataAtual.getMonth() - mesInicial + 1, mesesArray.length);
    }
  }

  return mesesArray;
}

export function descricaoTipoRelatorio() {
  let desc = 'Procedimento(s) Preparatório(s)';
  switch (parseInt(localStorage.getItem('tipoRelatorio'), 10)) {
    case 2: desc = 'Inquérito(s) Civil(is)'; break;
    case 3: desc = 'Procedimento(s) Investigatório(s) Criminal(is)'; break;
    case 4: desc = 'Inquérito(s) Policial(is)'; break;
    case 5: desc = 'Termo(s) de Ajustamento de Conduta'; break;
    case 6: desc = 'Recomendação(ões) Expedida(s)'; break;
    case 7: desc = 'Processo(s)'; break;
    case 8: desc = 'Termo(s) de Ajustamento de Conduta'; break;
    case 9: desc = 'Inquérito(s) Civil(is)'; break;
    case 10: desc = 'Processo(s)'; break;
    case 11: desc = 'Audiência(s) Pública(s) Realizada(s) Resolução 92 CNMP'; break;
    default: desc = 'Procedimento(s) Preparatório(s)';
  }
  return desc;
}

export function getHintDocumento(urlDocumento) {
  if (urlDocumento) {
    return 'Visualizar documento';
  }
  return 'Documento indisponível';
}

export function getTextoCabecalho(tipoProcesso) {
  let textoCabecalhoGrande;
  let textoCabecalhoPequeno;
  const tipoRelatorio = parseInt(localStorage.getItem('tipoRelatorio'), 10);
  const textoVazio = '';

  switch (tipoRelatorio) {
    case TipoRelatorioEnum.PROCEDIMENTO_PREPARATORIO: {
      textoCabecalhoGrande = 'Registros de Procedimentos Preparatórios com seus andamentos no MP';
      textoCabecalhoPequeno = textoVazio;
      break;
    }
    case TipoRelatorioEnum.INQUERITO_CIVIL: {
      textoCabecalhoGrande = 'Inquéritos Civis e seus andamentos no MP';
      textoCabecalhoPequeno = textoVazio;
      break;
    }
    case TipoRelatorioEnum.PROCEDIMENTO_INVESTIGATORIO: {
      textoCabecalhoGrande = 'Procedimentos de Investigação Criminal e seus andamentos no MP';
      textoCabecalhoPequeno = textoVazio;
      break;
    }
    case TipoRelatorioEnum.INQUERITO_POLICIAL: {
      textoCabecalhoGrande = 'Inquéritos Policiais e seus andamentos no MP';
      textoCabecalhoPequeno = textoVazio;
      break;
    }
    case TipoRelatorioEnum.TAC: {
      textoCabecalhoGrande = 'Termos de Ajustamentos de Conduta firmados';
      textoCabecalhoPequeno = textoVazio;
      break;
    }
    case TipoRelatorioEnum.RECOMENDACAO: {
      textoCabecalhoGrande = 'Recomendações Expedidas';
      textoCabecalhoPequeno = textoVazio;
      break;
    }
    case TipoRelatorioEnum.PROCESSO_JUDICIAL_EXTRAJUDICIAL: {
      textoCabecalhoGrande = 'Processos Judiciais e Extrajudiciais';
      textoCabecalhoPequeno = 'Resolução nº 89, 28 de agosto de 2012';
      break;
    }
    case TipoRelatorioEnum.INQUERITO_CIVIL_2011: {
      textoCabecalhoGrande = 'Inquéritos Civis';
      textoCabecalhoPequeno = 'RC nº 2, de 21 de junho de 2011';
      break;
    }
    case TipoRelatorioEnum.TAC_2011: {
      textoCabecalhoGrande = 'Termo de Ajustamento de Conduta';
      textoCabecalhoPequeno = 'RC nº 2, de 21 de junho de 2011';
      break;
    }
    case TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014: {
      textoCabecalhoGrande = `Distribuições de Processos ${tipoProcesso > 0 ? ` - ${tiposProcessos.filter(item => item.value === tipoProcesso)[0].label}` : ''}`;
      textoCabecalhoPequeno = 'Resolução 110/2014';
      break;
    }
    case TipoRelatorioEnum.AUDIENCIAS_PUBLICAS_RESOLUCAO_92: {
      textoCabecalhoGrande = 'Audiências Públicas Realizadas';
      textoCabecalhoPequeno = 'Resolução 92 CNMP';
      break;
    }
    default: textoCabecalhoGrande = textoVazio; textoCabecalhoPequeno = textoVazio;
  }

  return { textoCabecalhoGrande, textoCabecalhoPequeno };
}

export function getClassName(width) {
  if (width !== 'xs') {
    return 'float-left';
  }
  return '';
}

export function getDatasExibicao(listaDatas, tipoData, label) {
  const tiposRelatorios = [TipoRelatorioEnum.TAC_2011];
  const tipoRelatorio = parseInt(localStorage.getItem('tipoRelatorio'), 10);
  if (listaDatas) {
    if (tipoData === 'ASS' && tiposRelatorios.includes(tipoRelatorio)) {
      return `${label}: ${JSON.parse(listaDatas).data}`;
    }
    if (tipoData === 'REM' && tipoRelatorio === TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014) {
      return `${label}: ${moment(listaDatas).format('DD/MM/YYYY')}`;
    }
    if (tipoData === 'MOV' && tipoRelatorio === TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014) {
      return `${label}: ${moment(listaDatas).format('DD/MM/YYYY')}`;
    }
    if (Array.isArray(listaDatas)) {
      const item = listaDatas.filter(itemData => itemData.tipo_data === tipoData);
      if (item.length > 0) {
        return `${label}: ${moment(item[0].data_movimento).format('DD/MM/YYYY')}`;
      }
    }
  }
  return '';
}

const tipoParteEnum = {
  PARTE_ATIVA: '1',
  PARTE_PASSIVA: '2',
  PARTE_TERCEIRO: '3',
  PARTE_ADVOGADO: '4',
  PARTE_REPRESENTANTE_LEGAL: '5',
  PARTE_TESTEMUNHA: '6',
  PARTE_PERITO: '7',
  PARTE_ASSISTENTE: '8',
  PARTE_CESSIONARIO: '9',
};

const getDescricaoTipoParte = (tipoParte) => {
  const listaTipoParte = [
    { tipoParte: tipoParteEnum.PARTE_ATIVA, descricao: 'Parte ativa:' },
    { tipoParte: tipoParteEnum.PARTE_PASSIVA, descricao: 'Parte passiva:' },
    { tipoParte: tipoParteEnum.PARTE_TERCEIRO, descricao: 'Terceiro(s):' },
    { tipoParte: tipoParteEnum.PARTE_ADVOGADO, descricao: 'Advogado(s):' },
    { tipoParte: tipoParteEnum.PARTE_REPRESENTANTE_LEGAL, descricao: 'Representante(s):' },
    { tipoParte: tipoParteEnum.PARTE_TESTEMUNHA, descricao: 'Testemunha(s):' },
    { tipoParte: tipoParteEnum.PARTE_PERITO, descricao: 'Perito(s):' },
    { tipoParte: tipoParteEnum.PARTE_ASSISTENTE, descricao: 'Assistente(s)' },
    { tipoParte: tipoParteEnum.PARTE_CESSIONARIO, descricao: 'Cessionário(s)' },
  ];
  return listaTipoParte.filter(item => item.tipoParte === tipoParte)[0].descricao;
};

const getCategoriaParticipacao = (listaRepresentantes) => {
  let representantes = '';
  const listaResult = [];

  const distinctTipoParticipacao = [...new Set(listaRepresentantes.map(x => x.tipo_representante))];
  distinctTipoParticipacao.forEach((tipoRepresentante) => {
    listaRepresentantes.filter(i => i.tipo_representante === tipoRepresentante).forEach((itemRepresentante) => {
      representantes += `${itemRepresentante.nome_representante}, `;
    });
    if (representantes) {
      listaResult.push({ tipo_representante: getDescricaoTipoParte(tipoRepresentante), nome_representante: representantes.substring(0, representantes.length - 2) });
      representantes = '';
    }
  });

  return listaResult.sort((a, b) => ((a.nome_representante > b.nome_representante) ? 1 : -1));
};

const getJSON = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const deepClone = value => JSON.parse(JSON.stringify(value));

export const getListaParteTratada = (listaParte) => {
  const listaParteTratada = [];

  listaParte.forEach((itemParte, index) => {
    listaParteTratada.push(deepClone(itemParte));
    const listaRepresentantes = getJSON(itemParte.lista_representantes);
    listaParteTratada[index].lista_representantes = getCategoriaParticipacao(listaRepresentantes);
  });

  return listaParteTratada;
};

export function pegarStringComoArray(campo, separador) {
  return campo == null ? null : campo.split(separador);
}

export function getUserResponsavel(str, separador) {
  if (!str) {
    return '';
  }
  const UserDataVista = pegarStringComoArray(str, separador);
  const UsuarioResp = UserDataVista[0];
  return `Membro: ${UsuarioResp}`;
}

export function getDataVista(str, separador) {
  if (!str) {
    return '';
  }
  const UserDataVista = pegarStringComoArray(str, separador);
  if (UserDataVista.length > 1) {
    const DataVista = UserDataVista[1];
    return DataVista;
  }
  return '';
}

export const ExibeDadosVistaEnum = {
  NAO_CONFIGURADO: '0',
  EXIBE_DATA_VISTA: '1',
  EXIBE_MEMBRO: '2',
};
