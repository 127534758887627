import { createReducer } from '../../commons/redux/createReducer';
import initialState from './DetalheProcesso.initialState';
import * as actions from './DetalheProcesso.actions';

const fetchDetalheProcesso = state => ({
  ...state,
  loading: true,
});

const fetchDetalheProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  data: action.data,
  last: action.data.last,
  listaTramitacao: action.data.content,
});

const fetchDetalheProcessoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const DetalheProcessoReducer = createReducer(initialState, {
  [actions.fetchDetalheProcessoAction().type]: fetchDetalheProcesso,
  [actions.fetchDetalheProcessoActionSuccess().type]: fetchDetalheProcessoSuccess,
  [actions.fetchDetalheProcessoActionError().type]: fetchDetalheProcessoError,
});

export default DetalheProcessoReducer;
