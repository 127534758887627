import React from 'react';
import ReactDOM from 'react-dom';
import './css/bootstrap.min.css';
import './css/sp-bootstrap.css';
import './css/timeline.css';
import './sass/teste.scss';

import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

// i18n
import { addLocaleData, IntlProvider } from 'react-intl';
import pt from 'react-intl/locale-data/pt';
import es from 'react-intl/locale-data/es';
import en from 'react-intl/locale-data/en';
import theme from './utils/theme';
import * as serviceWorker from './serviceWorker';
import { localeBuilder, flattenMessages } from './i18n/utils';
import messages from './i18n/messages';

// Redux
import { history, configureStore } from './store/configureStore';

import { initializeReactGA } from './commons/analytics';

import DetalheProcesso from './features/detalheProcesso/DetalheProcesso';
import ConsultaProcesso from './features/consultaProcesso/ConsultaProcesso';
import PesquisaProcesso from './features/pesquisaProcesso/PesquisaProcesso';
import MovimentacaoUnidade from './features/movimentacaoUnidade/MovimentacaoUnidade';

addLocaleData([...pt, ...es, ...en]);
const { languages } = navigator;
const locale = localeBuilder(languages);
const reduxStore = configureStore();

initializeReactGA();

ReactDOM.render(
  <IntlProvider
    locale={locale}
    messages={flattenMessages(messages[locale])}
    textComponent={React.Fragment}
  >
    <Provider store={reduxStore}>
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route path="/portaltransparencia/procedimentopreparatorio" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/inqueritocivil" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/procedimentoinvestigatorio" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/inqueritopolicial" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/termoajustamentoconduta" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/recomendacoesexpedidas" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/resolucao-89" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/termoajustamentoconduta-resolucao-2-2011" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/inqueritocivil-resolucao-2-2011" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/consulta-distribuicao-110-2014" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/movimentacaounidade" component={MovimentacaoUnidade} />
            <Route path="/portaltransparencia/audienciaspublicas-resolucao-92" component={PesquisaProcesso} />
            <Route path="/portaltransparencia/consulta" component={ConsultaProcesso} />
            <Route path="/portaltransparencia/detalhe" component={DetalheProcesso} />
            <Route path="/portaltransparencia/health" component={() => (<div>PONG</div>)} />
            <Route path="*" component={() => (<div>Página não encontrada!</div>)} />
          </Switch>
        </MuiThemeProvider>
      </Router>
    </Provider>
  </IntlProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
