import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Loader, Typography, RadioGroup, Radio, Select } from '@bayon/commons';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import * as actions from './MovimentacaoUnidade.epics';
import { getAnos, getMeses } from '../../utils/utils';
import * as alertActions from '../../components/alert/Alert.epics';
import Alerts from '../../components/alerts/Alerts';

const styles = () => ({
  colorPadding: { paddingTop: 20, paddingLeft: 20, paddingRight: 20, backgroundColor: '#F2F2F2' },
  padding: {
    paddingLeft: 15,
    paddingBottom: 15,
  },
});

class MovimentacaoUnidade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ano: '',
      anos: getAnos(),
      mes: '',
      meses: [],
      disabled: true,
      tpOrgao: '',
      anoSelecionado: true,
      mesSelecionado: true,
      tpOrgaoSelecionado: true,
    };
  }

  componentDidMount = async () => {
    const { fetchAnoMesInicial, openAlertError, closeAlert } = this.props;
    await fetchAnoMesInicial();
    const { anoMesInicial } = this.props;
    closeAlert();
    if (!anoMesInicial) {
      openAlertError('Parâmetro MESANOINICIAL não configurado, favor entrar em contato com o administrador do sistema!');
      return;
    }
    this.setState({ anos: getAnos(anoMesInicial.toString().substring(0, 4)) });
  }

  handleChangeAno = (value) => {
    const { anoMesInicial } = this.props;
    this.setState({ ano: value, anoSelecionado: !!value, disabled: false, meses: getMeses(value, anoMesInicial.toString()) });
  }

  getReport = async () => {
    const { ano, mes, tpOrgao } = this.state;
    const { fetchMovimentacaoUnidade } = this.props;
    this.setState({ tpOrgaoSelecionado: !!tpOrgao, anoSelecionado: !!ano, mesSelecionado: !!mes });
    if (!tpOrgao || !ano || !mes) {
      return;
    }
    await fetchMovimentacaoUnidade(tpOrgao, `${ano}${mes}`);
    this.downloadXLS();
    this.setState({ tpOrgao: '', ano: '', mes: '', disabled: true });
  }

  openPDF = () => {
    const { base64, openAlertError, closeAlert, msgErro } = this.props;
    closeAlert();
    if (!base64) {
      openAlertError(`Não foi possível gerar o relatório. Por favor, tente novamente mais tarde. ( ${msgErro} )`);
      return;
    }
    const pdfWindow = window.open('', '_blank');
    pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,${base64}'></iframe>`);
  }

  downloadPDF = () => {
    const { base64 } = this.props;
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/pdf;base64,${base64}`;
    downloadLink.download = 'arquivo.pdf';
    downloadLink.click();
  }

  downloadXLS = () => {
    const { base64 } = this.props;
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/vnd.ms-excel;base64,${base64}`;
    downloadLink.download = 'relatorio.xlsx';
    downloadLink.click();
  }

  render() {
    const { classes, loading } = this.props;
    const { anoSelecionado, anos, mesSelecionado, meses, disabled, tpOrgaoSelecionado } = this.state;

    if (loading) {
      return <div style={{ position: 'absolute', left: '50%', top: '50%' }}><Loader size="large" color="blue" label="Carregando" /></div>;
    }

    return (
      <Fragment>
        <Alerts />
        <Grid container style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="container-fluid">
            <div className="row">
              <div className="float-left" style={{ marginLeft: 15 }}>
                <Typography size={21} weight="semibold">Dados e Estatística da Movimentação Processual por Unidade</Typography>
              </div>
            </div>
            <div className={classes.colorPadding}>
              <div className="row">
                <Grid item xs={12} sm={2} style={{ paddingLeft: 15, paddingBottom: 15 }}>
                  <Typography size={14} color="neutralTertiary">Selecione um órgão</Typography>
                  <RadioGroup
                    id="rgTipoOrgao"
                    name="rgTipoOrgao"
                    error={!tpOrgaoSelecionado}
                    errorText="Campo obrigatório"
                    aria-label="Selecione um órgão"
                    onChange={(ev) => { this.setState({ tpOrgao: ev.target.value, tpOrgaoSelecionado: !!ev.target.value }); }}
                  >
                    <Radio id="PC" value="1" label="Procuradorias Cíveis e Criminais" />
                    <Radio id="PM" value="2" label="Promotorias" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={2} style={{ paddingLeft: 15, paddingBottom: 15 }}>
                  <Select
                    id="ano"
                    name="ano"
                    isError={!anoSelecionado}
                    errorMessage="Campo obrigatório"
                    label="Ano"
                    size="medium"
                    options={anos}
                    onChange={(ev) => { this.handleChangeAno(ev.value); }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} style={{ paddingLeft: 15, paddingBottom: 15 }}>
                  <Select
                    id="mes"
                    name="mes"
                    isError={!disabled && !mesSelecionado}
                    errorMessage="Campo obrigatório"
                    label="Mês"
                    size="medium"
                    options={meses}
                    onChange={(ev) => { this.setState({ mes: (`00${ev.value}`).slice(-2), mesSelecionado: !!ev.value }); }}
                    disabled={disabled}
                  />
                </Grid>
              </div>
              <div className="row" style={{ paddingLeft: 15, paddingBottom: 15 }}>
              <Grid item>
                <Button
                  id="btnConsultar"
                  name="btnConsultar"
                  aria-label="Consultar"
                  size="medium"
                  variant="primary"
                  onClick={this.getReport}
                >Consultar
                </Button>
              </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  }
}

MovimentacaoUnidade.propTypes = {
  base64: PropTypes.string.isRequired,
  msgErro: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  closeAlert: PropTypes.func.isRequired,
  openAlertError: PropTypes.func.isRequired,
  fetchMovimentacaoUnidade: PropTypes.func.isRequired,
  fetchAnoMesInicial: PropTypes.func.isRequired,
  anoMesInicial: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  base64: state.movimentacaoUnidadeReducer.base64,
  msgErro: state.movimentacaoUnidadeReducer.msgErro,
  anoMesInicial: state.movimentacaoUnidadeReducer.anoMesInicial,
  loading: state.movimentacaoUnidadeReducer.loading ? state.movimentacaoUnidadeReducer.loading : false,
});

const mapDispatchToProps = { ...actions, ...alertActions };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MovimentacaoUnidade));
