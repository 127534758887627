import { createReducer } from '../../commons/redux/createReducer';
import initialState from './PesquisaProcesso.initialState';
import * as actions from './PesquisaProcesso.actions';

const fetchPesquisaProcesso = state => ({
  ...state,
  loading: true,
});

const fetchPesquisaProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  processos: action.data.content,
  rowCount: action.data.total_elements,
  totalPages: action.data.total_pages,
  cdOrgao: action.data.cdOrgao,
  numeroMP: action.data.numeroMP,
  cdSituacao: action.data.cdSituacao,
  tipoRelatorio: action.data.tipoRelatorio,
  parteInteressada: action.data.parteInteressada,
  dataInicial: action.data.dataInicial,
  dataFinal: action.data.dataFinal,
  tipoProcesso: action.data.tipoProcesso,
});

const fetchPesquisaProcessoError = (state, action) => ({
  ...state,
  loading: false,
  rowCount: 0,
  processos: [],
  error: action.error,
  tipoRelatorio: action.error.tipoRelatorio,
  tipoProcesso: action.error.tipoProcesso,
});

const PesquisaProcessoReducer = createReducer(initialState, {
  [actions.fetchPesquisaProcessoAction().type]: fetchPesquisaProcesso,
  [actions.fetchPesquisaProcessoActionSuccess().type]: fetchPesquisaProcessoSuccess,
  [actions.fetchPesquisaProcessoActionError().type]: fetchPesquisaProcessoError,
});

export default PesquisaProcessoReducer;
