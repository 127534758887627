import { createReducer } from '../../commons/redux/createReducer';

import * as actions from './Alert.actions';
import initialState from './Alert.initialState';

const replaceInArray = (array, element) => array.map(current => (current.id === element.id ? element : current));
const addInArray = (array, element) => ([...array, ...[element]]);

const buscarAlerta = (alert, alerts) => alerts.find(element => element.id === alert.id);

const openAlert = (state, action) => {
  const found = buscarAlerta(action.alert, state.alerts);
  return {
    ...state,
    alerts: !!found ? replaceInArray(state.alerts, action.alert) : addInArray(state.alerts, action.alert),
  };
};

const openAlertSuccess = (state, action) => {
  const found = buscarAlerta(action.alert, state.alerts);
  return {
    ...state,
    alerts: !!found ? replaceInArray(state.alerts, action.alert) : addInArray(state.alerts, action.alert),
  };
};

const openAlertError = (state, action) => {
  const found = buscarAlerta(action.alert, state.alerts);
  return {
    ...state,
    alerts: !!found ? replaceInArray(state.alerts, action.alert) : addInArray(state.alerts, action.alert),
  };
};

const closeAlert = state => ({
  ...state,
  alerts: [],
});

const alertReducer = createReducer(initialState, {
  [actions.openAlertAction().type]: openAlert,
  [actions.openAlertSuccessAction().type]: openAlertSuccess,
  [actions.openAlertErrorAction().type]: openAlertError,
  [actions.closeAlertAction().type]: closeAlert,
});

export default alertReducer;
