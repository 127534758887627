import uuidv4 from 'uuid/v4';

import {
  openAlertSuccessAction,
  openAlertErrorAction,
  closeAlertAction,
} from './Alert.actions';

const SUCCESS = 'success';
const WARNING = 'warning';

const montarObjetoAlerta = (id, type, message, messageBold, key) => ({
  id,
  type,
  message,
  messageBold,
  key,
});

export const openAlertSuccess = message => (dispatch) => {
  dispatch(openAlertSuccessAction(montarObjetoAlerta(uuidv4(), SUCCESS, message)));
};

export const openAlertError = (message, messageBold) => (dispatch) => {
  dispatch(openAlertErrorAction(montarObjetoAlerta(1/* uuidv4() */, WARNING, message, messageBold)));
};

export const closeAlert = () => (dispatch) => {
  dispatch(closeAlertAction());
};
