import request from '../../utils/request';
import { fetchOrgaoAction, fetchOrgaoActionError, fetchOrgaoActionSuccess } from './Orgao.actions';

export const fetchOrgao = () => async (dispatch) => {
  dispatch(fetchOrgaoAction());
  try {
    const { data } = await request.get('/consulta/orgaos-portaltransparencia');
    data.data.unshift({ orgao_pk: { codigo_orgao: '' }, descricao: 'Selecione um órgão' });
    dispatch(fetchOrgaoActionSuccess(data.data));
  } catch (err) {
    dispatch(fetchOrgaoActionError(err.data));
  }
};
