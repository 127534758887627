import { createReducer } from '../../commons/redux/createReducer';
import initialState from './Orgao.initialState';
import * as actions from './Orgao.actions';

const fetchOrgao = state => ({
  ...state,
  loading: true,
});

const fetchOrgaoSuccess = (state, action) => ({
  ...state,
  loading: false,
  orgao: action.data,
  error: false,
});

const fetchOrgaoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const OrgaoReducer = createReducer(initialState, {
  [actions.fetchOrgaoAction().type]: fetchOrgao,
  [actions.fetchOrgaoActionSuccess().type]: fetchOrgaoSuccess,
  [actions.fetchOrgaoActionError().type]: fetchOrgaoError,
});

export default OrgaoReducer;
