import React from 'react';
import PropTypes from 'prop-types';
import Informacao from '@bayon/svg-icons/icons/ExclamationCircle';

const Alert = ({ alert, closeAlert }) => (
  <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
    <div style={{ display: 'table' }}>
      <div style={{ float: 'left', width: 20, paddingTop: 3 }}>
        <Informacao style={{ height: '16px', width: '16px' }} />
      </div>
      <div style={{ marginLeft: 25, color: 'black' }}>
        {alert.message}<strong>{alert.messageBold}</strong>
      </div>
    </div>
    <button id="CloseAlert" type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => closeAlert(alert)}>x</button>
  </div>
);

Alert.propTypes = {
  alert: PropTypes.object.isRequired,
  closeAlert: PropTypes.func.isRequired,
};

export default Alert;
