import moment from 'moment';
import { TipoRelatorioEnum, ExibeDadosVistaEnum } from './utils';
import * as config from './configs';

export function exibeTipoProcesso() {
  const tiposRelatorios = [TipoRelatorioEnum.TAC_2011, TipoRelatorioEnum.PROCESSO_JUDICIAL_EXTRAJUDICIAL, TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014];
  return tiposRelatorios.includes(parseInt(localStorage.getItem('tipoRelatorio'), 10));
}

export function exibeHintTipoProcesso(ultimaMovimentacaoTacRec) {
  const tiposRelatorios = [TipoRelatorioEnum.PROCESSO_JUDICIAL_EXTRAJUDICIAL];
  return ultimaMovimentacaoTacRec && tiposRelatorios.includes(parseInt(localStorage.getItem('tipoRelatorio'), 10));
}

export function exibeLinkPastaDigital() {
  const tiposRelatorios = [TipoRelatorioEnum.TAC_2011];
  return tiposRelatorios.includes(parseInt(localStorage.getItem('tipoRelatorio'), 10));
}
export function exibeOpcaoExportarRelatorio() {
  const tiposRelatorios = [TipoRelatorioEnum.PROCESSO_JUDICIAL_EXTRAJUDICIAL, TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014, TipoRelatorioEnum.INQUERITO_CIVIL_2011, TipoRelatorioEnum.TAC_2011];
  return tiposRelatorios.includes(parseInt(localStorage.getItem('tipoRelatorio'), 10));
}

export function exibeProcessoComoLink() {
  const tiposRelatorios = [TipoRelatorioEnum.TAC_2011, TipoRelatorioEnum.INQUERITO_CIVIL_2011, TipoRelatorioEnum.PROCESSO_JUDICIAL_EXTRAJUDICIAL, TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014];
  return !tiposRelatorios.includes(parseInt(localStorage.getItem('tipoRelatorio'), 10));
}

export function exibeRodape(datas, assinatura) {
  const tipoRelatorio = parseInt(localStorage.getItem('tipoRelatorio'), 10);
  const tiposRelatorios = [TipoRelatorioEnum.TAC_2011];
  if (datas || (assinatura && tiposRelatorios.includes(tipoRelatorio)) || tipoRelatorio === TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014) {
    return '';
  }
  return 'none';
}

export function exibeResultadosMesmaPagina(tipoRelatorio) {
  return config.getExibirFiltros()
    && (tipoRelatorio !== TipoRelatorioEnum.CONSULTA_DISTRIBUICAO_110_2014);
}

export function exibeMembroResponsavel() {
  const habilitamenbroresponsavel = config.getHabilitaMembroRespDataVista() || [];
  return habilitamenbroresponsavel.includes(ExibeDadosVistaEnum.EXIBE_MEMBRO);
}

export function exibeDataVista() {
  const habilitadatavista = config.getHabilitaMembroRespDataVista() || [];
  return habilitadatavista.includes(ExibeDadosVistaEnum.EXIBE_DATA_VISTA);
}

export function formataNomeExibicaoDoc(movimentacao, dtMovimento) {
  return movimentacao.concat(' (', moment(dtMovimento).utc(false).format('DD/MM/YYYY'), ')');
}

export function getDocs(item) {
  let docs = [];
  if (Object.prototype.hasOwnProperty.call(item, 'lista_movimentacoes') && item.lista_movimentacoes.length) {
    docs = item.lista_movimentacoes.filter(oitem => oitem.url_documento);
    docs.reverse();
  }
  return docs;
}
