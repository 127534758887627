import request from '../../utils/request';
import { QUANTIDADE_PAGINACAO_DETALHE } from '../../commons/constants';
import {
  fetchDistribuicaoProcessoAction,
  fetchDistribuicaoProcessoActionError,
  fetchDistribuicaoProcessoActionSuccess,
} from './DistribuicaoProcesso.actions';

export const carregarMaisDistribuicoes = (cdProcesso, page, listaDistribuicao) => async (dispatch) => {
  dispatch(fetchDistribuicaoProcessoAction());
  try {
    const { data } = await request.get(`/consulta/distribuicoes-portaltransparencia?codigo_processo=${cdProcesso}&numero_pagina=${page}`);
    data.data.cdProcesso = cdProcesso;
    data.data.content = listaDistribuicao.concat(data.data.content);
    data.data.numeroPaginaDistribuicao = page;
    dispatch(fetchDistribuicaoProcessoActionSuccess(data.data));
  } catch (err) {
    dispatch(fetchDistribuicaoProcessoActionError(err.data));
  }
};

export const fetchLoadLess = (cdProcessoMovimentacao, listaDistribuicao) => async (dispatch) => {
  dispatch(fetchDistribuicaoProcessoAction());
  try {
    const totalListaMovimentacao = listaDistribuicao.length;
    listaDistribuicao.splice(QUANTIDADE_PAGINACAO_DETALHE, listaDistribuicao.length - QUANTIDADE_PAGINACAO_DETALHE);
    const data = { cdProcesso: cdProcessoMovimentacao, last: false, content: listaDistribuicao, total_elements: totalListaMovimentacao, numeroPaginaDistribuicao: 0 };
    dispatch(fetchDistribuicaoProcessoActionSuccess(data));
  } catch (err) {
    dispatch(fetchDistribuicaoProcessoActionError('Houve uma falha ao tentar carregar menos distribuições!'));
  }
};
