import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AngleDown from '@bayon/svg-icons/icons/AngleDown';
import AngleUp from '@bayon/svg-icons/icons/AngleUp';
import moment from 'moment';
import { Typography } from '@bayon/commons';
import * as actions from './DistribuicaoProcesso.epics';
import styles from '../../commons/styles';

function DistribuicaoProcesso(props) {
  const { classes, carregarMaisDistribuicoes, fetchLoadLess, listaDistribuicao, lastDistribuicao, cdProcesso, totalElements, numeroPaginaDistribuicao } = props;

  function loadMore(e) {
    e.preventDefault();
    carregarMaisDistribuicoes(cdProcesso, numeroPaginaDistribuicao + 1, listaDistribuicao);
  }

  function loadLess(e) {
    e.preventDefault();
    fetchLoadLess(cdProcesso, listaDistribuicao);
  }

  return (
    <div className={classes.root}>
      <div className={classes.colorPaddingDistribuicao}>
        <div className={classes.colorPaddingInterna}>
          <div className={classes.fontCabecalhoCard}>Distribuições</div>
          {!listaDistribuicao.length > 0 && (
            <div>
              <br />
              <div><Typography size={14} color="neutralTertiary">Não existem distribuições para este cadastro.</Typography></div>
            </div>
          )}
          {listaDistribuicao.length > 0 && (
            <div>
              <Hidden smDown>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tablecell} style={{ width: '15%' }}>Data distribuição</TableCell>
                      <TableCell className={classes.tablecell} style={{ width: '70%' }}>Órgão</TableCell>
                      <TableCell className={classes.tablecell} style={{ width: '15%' }}>Data entrega</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaDistribuicao.map(item => (
                      <TableRow key={item.distribuicao_pk.numero_sequencial}>
                        <TableCell className={classes.tablecell}><Typography size={14}>{moment(item.data_distribuicao).format('DD/MM/YYYY')}</Typography></TableCell>
                        <TableCell className={classes.tablecell}>{item.descricao_orgao}</TableCell>
                        <TableCell className={classes.tablecell}><Typography size={14}>{moment(item.data_distribuicao).format('DD/MM/YYYY')}</Typography></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.rootCard}>
                  {listaDistribuicao.map(item => (
                    <Grid container key={item.distribuicao_pk.numero_sequencial} className={classes.li}>
                      <Grid item xs={12} sm={3} className={classes.col}>
                        <div className={classes.unjLabel}> Data distribuição </div>
                        <div><Typography size={14}>{moment(item.data_distribuicao).format('DD/MM/YYYY')}</Typography></div>
                      </Grid>
                      <Grid item xs={12} sm={3} className={classes.col}>
                        <div className={classes.unjLabel}> Órgão </div>
                        <div>{item.descricao_orgao}</div>
                      </Grid>
                      <Grid item xs={12} sm={2} className={classes.col}>
                        <div className={classes.unjLabel}> Data entrega </div>
                        <div><Typography size={14}>{moment(item.data_distribuicao).format('DD/MM/YYYY')}</Typography></div>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Hidden>
            </div>
          )}
          <div style={{ float: 'right', position: 'relative', marginLeft: 10 }}>
            {!lastDistribuicao && totalElements > 5 && (
              <a href="#"
                id="btnMostrarMais"                
                name="btnMostrarMais"                
                onClick={loadMore}
                style={{ color: '#0078d7' }}
              >
                <AngleDown style={{ height: 14, width: 14, paddingRight: 2 }} />
                Mais
              </a>
            )}
          </div>
          <div style={{ float: 'right', position: 'relative' }}>
            {numeroPaginaDistribuicao > 0 && totalElements > 5 && (
              <a href="#"
                id="btnMostrarMenos"
                name="btnMostrarMenos"                
                onClick={loadLess}
                style={{ color: '#0078d7' }}
              >
                <AngleUp style={{ height: 14, width: 14, paddingRight: 2 }} />
                Menos
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

DistribuicaoProcesso.propTypes = {
  classes: PropTypes.object.isRequired,
  carregarMaisDistribuicoes: PropTypes.func.isRequired,
  fetchLoadLess: PropTypes.func.isRequired,
  lastDistribuicao: PropTypes.bool.isRequired,
  totalElements: PropTypes.number.isRequired,
  listaDistribuicao: PropTypes.array.isRequired,
  cdProcesso: PropTypes.string.isRequired,
  numeroPaginaDistribuicao: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  lastDistribuicao: state.distribuicaoProcessoReducer.lastDistribuicao,
  listaDistribuicao: state.distribuicaoProcessoReducer.listaDistribuicao,
  cdProcesso: state.distribuicaoProcessoReducer.cdProcesso,
  totalElements: state.distribuicaoProcessoReducer.totalElements,
  numeroPaginaDistribuicao: state.distribuicaoProcessoReducer.numeroPaginaDistribuicao,
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DistribuicaoProcesso));
