// 99.9999.99999999-9
export function formatarNumeroMP(numeroMP) {
  let newNumeroMP = numeroMP.substring(0, 18);

  newNumeroMP = newNumeroMP.replace(/\D/g, '');
  newNumeroMP = newNumeroMP.replace(/(\d)(\d{15})$/, '$1.$2');
  newNumeroMP = newNumeroMP.replace(/(\d)(\d{13})$/, '$1.$2');
  newNumeroMP = newNumeroMP.replace(/(\d)(\d{9})$/, '$1.$2');
  newNumeroMP = newNumeroMP.replace(/(\d)(\d{1})$/, '$1-$2');

  return newNumeroMP;
}
