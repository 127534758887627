import * as actions from './MovimentacaoProcesso.actions';
import initialState from './MovimentacaoProcesso.initialState';
import { createReducer } from '../../commons/redux/createReducer';

const fetchMovimentacaoProcesso = state => ({
  ...state,
  loading: true,
});

const fetchMovimentacaoProcessoSuccess = (state, action) => ({
  ...state,
  error: false,
  loading: false,
  lastMovimentacao: action.data.last,
  listaMovimentacao: action.data.content,
  cdProcesso: action.data.cdProcesso,
  totalElements: action.data.total_elements,
  numeroPaginaMovimentacao: action.data.numeroPaginaMovimentacao,
});

const fetchMovimentacaoProcessoError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
  listaMovimentacao: [],
  totalElements: 0,
});

const fetchDatasMovimentacaoProcessoSuccess = (state, action) => ({
  ...state,
  dtInstauracao: action.data.filter(item => item.tipo_data === 'INS').length > 0 ? action.data.filter(item => item.tipo_data === 'INS')[0].data_movimento : '',
  dtArquivamento: action.data.filter(item => item.tipo_data === 'ARQ').length > 0 ? action.data.filter(item => item.tipo_data === 'ARQ')[0].data_movimento : '',
  dtHomologacao: action.data.filter(item => item.tipo_data === 'HOM').length > 0 ? action.data.filter(item => item.tipo_data === 'HOM')[0].data_movimento : '',
});

const fetchDatasMovimentacaoProcessoError = (state, action) => ({
  ...state,
  dtInstauracao: '',
  dtArquivamento: '',
  dtHomologacao: '',
  error: action.error,
});

const MovimentacaoProcessoReducer = createReducer(initialState, {
  [actions.fetchMovimentacaoProcessoAction().type]: fetchMovimentacaoProcesso,
  [actions.fetchMovimentacaoProcessoActionSuccess().type]: fetchMovimentacaoProcessoSuccess,
  [actions.fetchMovimentacaoProcessoActionError().type]: fetchMovimentacaoProcessoError,
  [actions.fetchDatasMovimentacaoProcessoActionSuccess().type]: fetchDatasMovimentacaoProcessoSuccess,
  [actions.fetchDatasMovimentacaoProcessoActionError().type]: fetchDatasMovimentacaoProcessoError,
});

export default MovimentacaoProcessoReducer;
