import { createStore, applyMiddleware } from 'redux';
import multi from 'redux-multi';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

import { rootReducer } from './root';

export const history = createBrowserHistory();

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export const configureStore = () => (
  applyMiddleware(multi, thunk, promise)(createStore)(rootReducer, reduxDevTools)
);
