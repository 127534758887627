import merge from 'deepmerge';
import ptBr from './languages/ptBr';
import esEs from './languages/esEs';
import enUs from './languages/enUs';

const messages = {
  'pt-BR': ptBr,
  'es-ES': merge.all([ptBr, esEs]),
  'en-US': merge.all([ptBr, enUs]),
};

export default messages;
