import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AngleDown from '@bayon/svg-icons/icons/AngleDown';
import AngleUp from '@bayon/svg-icons/icons/AngleUp';
import FilePage from '@bayon/svg-icons/icons/FilePage';
import { Grid, withStyles, Typography, Tooltip, Checkbox } from '@bayon/commons';
import moment from 'moment';
import * as actions from './MovimentacaoProcesso.epics';
import styles from '../../commons/styles';
import * as utils from '../../utils/utils';

function MovimentacaoProcesso(props) {
  const { classes, fetchLoadMore, fetchLoadLess, listaMovimentacao, lastMovimentacao, cdProcesso, dtInstauracao, dtArquivamento, dtHomologacao, totalElements, tipoRelatorio, numeroPaginaMovimentacao, consultarMovimentacoes } = props;
  const [checked, setChecked] = useState(
    tipoRelatorio === utils.TipoRelatorioEnum.TAC ||
    tipoRelatorio === utils.TipoRelatorioEnum.RECOMENDACAO ||
    tipoRelatorio === utils.TipoRelatorioEnum.AUDIENCIAS_PUBLICAS_RESOLUCAO_92);

  function loadMore(e) {
    e.preventDefault();
    fetchLoadMore(cdProcesso, numeroPaginaMovimentacao + 1, listaMovimentacao, tipoRelatorio, checked);
  }

  function loadLess(e) {
    e.preventDefault();
    fetchLoadLess(cdProcesso, listaMovimentacao);
  }

  function handleFiltro() {
    setChecked(!checked);
    consultarMovimentacoes(cdProcesso, tipoRelatorio, !checked);
  }

  return (
    <div className={classes.root}>
      <div className={classes.colorPadding}>
        <div className={classes.colorPaddingInterna}>
          <div className={classes.fontCabecalhoCard} style={{ paddingBottom: 10 }}>Movimentações</div>
          {tipoRelatorio <= 4 && (
          <div style={{ paddingBottom: 10 }}>
            <div className={classes.quadroMovimentacao}>
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <div className={classes.unjLabel}> Data da instauração </div>
                  {dtInstauracao && (
                  <div>{moment(dtInstauracao).format('DD/MM/YYYY')}</div>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className={classes.unjLabel}> Data de promoção de arquivamento </div>
                  {dtArquivamento && (
                  <div>{moment(dtArquivamento).format('DD/MM/YYYY')}</div>
                  )}
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className={classes.unjLabel}> Data de homologação de arquivamento </div>
                  {dtHomologacao && (
                  <div>{moment(dtHomologacao).format('DD/MM/YYYY')}</div>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
          )}
          {!listaMovimentacao.length > 0 && (
            <div>
              <div><Typography size={14} color="neutralTertiary">Não existem movimentações para este cadastro.</Typography></div>
            </div>
          )}
          {listaMovimentacao.length > 0 && (
          <>
            {tipoRelatorio > 4 && (
            <div style={{ paddingBottom: 15 }}>
              <Checkbox
                size="medium"
                checked={checked}
                label={'Filtrar por ' + utils.descricaoTipoRelatorio()}
                onChange={handleFiltro}
              />
            </div>
            )}
            <div className="timeline-wrapper">
              <ul className="StepProgress">
                {listaMovimentacao.map(item => (
                  <li key={item.movimentacao_pk.numero_sequencial} className="StepProgress-item">
                    <div className="time"><Typography size={12}>{moment(item.data_movimento).format('DD/MM/YYYY')}</Typography></div>
                    {!item.exibe_documento && (
                    <div><Typography size={14} weight={item.exibe_destacado ? 'bold' : 'regular'}>{item.descricao_tipo_movimentacao}</Typography></div>
                    )}
                    {item.exibe_documento && (
                    <div>
                      <a href={item.url_documento} target="_blank" rel="noopener noreferrer">
                        <Typography size={14} weight={item.exibe_destacado ? 'bold' : 'regular'}>
                          <Tooltip
                            placement="top"
                            label={item.url_documento ? 'Visualizar documento' : 'Documento indisponível'}
                            aria-label={item.url_documento ? 'Visualizar documento' : 'Documento indisponível'}
                          >
                            <FilePage fill={item.url_documento ? '#0078D7' : '#A6A6A6'} style={{ height: 16, width: 16, marginRight: 10, marginBottom: 5 }} />
                          </Tooltip>
                          {item.descricao_tipo_movimentacao}
                        </Typography>
                      </a>
                    </div>
                    )}
                    <div className={classes.unjLabel}>{item.nome_usuario ? `Responsável: ${item.nome_usuario}` : ''}</div>
                  </li>
                ))}
              </ul>
            </div>
          </>
          )}
          <div style={{ float: 'right', position: 'relative', marginLeft: 10 }}>
            {!lastMovimentacao && totalElements > 5 && (
              <a href="#"
                id="btnMostrarMais"
                name="btnMostrarMais"                
                onClick={loadMore}
                style={{ color: '#0078d7' }}
              >
                <AngleDown style={{ height: 14, width: 14, paddingRight: 2 }} />
                Mais
              </a>
            )}
          </div>
          <div style={{ float: 'right', position: 'relative' }}>
            {numeroPaginaMovimentacao > 0 && totalElements > 5 && (
              <a href="#"
                id="btnMostrarMenos"
                name="btnMostrarMenos"                
                onClick={loadLess}
                style={{ color: '#0078d7' }}
              >
                <AngleUp style={{ height: 14, width: 14, paddingRight: 2 }} />
                Menos
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

MovimentacaoProcesso.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchLoadMore: PropTypes.func.isRequired,
  fetchLoadLess: PropTypes.func.isRequired,
  consultarMovimentacoes: PropTypes.func.isRequired,
  lastMovimentacao: PropTypes.bool.isRequired,
  totalElements: PropTypes.number.isRequired,
  listaMovimentacao: PropTypes.array.isRequired,
  cdProcesso: PropTypes.string.isRequired,
  dtInstauracao: PropTypes.string.isRequired,
  dtArquivamento: PropTypes.string.isRequired,
  dtHomologacao: PropTypes.string.isRequired,
  tipoRelatorio: PropTypes.number.isRequired,
  numeroPaginaMovimentacao: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  lastMovimentacao: state.movimentacaoProcessoReducer.lastMovimentacao,
  listaMovimentacao: state.movimentacaoProcessoReducer.listaMovimentacao,
  cdProcesso: state.movimentacaoProcessoReducer.cdProcesso,
  dtInstauracao: state.movimentacaoProcessoReducer.dtInstauracao,
  dtArquivamento: state.movimentacaoProcessoReducer.dtArquivamento,
  dtHomologacao: state.movimentacaoProcessoReducer.dtHomologacao,
  totalElements: state.movimentacaoProcessoReducer.totalElements,
  tipoRelatorio: state.pesquisaProcessoReducer.tipoRelatorio,
  numeroPaginaMovimentacao: state.movimentacaoProcessoReducer.numeroPaginaMovimentacao,
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MovimentacaoProcesso));
