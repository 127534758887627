import axios from 'axios';
import { BASE_URL, TIMEOUT } from '../commons/constants';

axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = BASE_URL;

axios.interceptors.response.use(
  data => data,
  (error) => {
    if (error.response) {
      return Promise.reject(error.response);
    }

    return Promise.reject(error);
  },
);

export default axios;
