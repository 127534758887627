import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import orgaoReducer from '../components/orgao/OrgaoReducer';
import situacaoReducer from '../components/situacao/SituacaoReducer';
import alertReducer from '../components/alert/alertReducer';
import pesquisaProcessoReducer from '../features/pesquisaProcesso/PesquisaProcessoReducer';
import consultaProcessoReducer from '../features/consultaProcesso/ConsultaProcessoReducer';
import detalheProcessoReducer from '../features/detalheProcesso/DetalheProcessoReducer';
import tramitacaoProcessoReducer from '../components/tramitacaoProcesso/TramitacaoProcessoReducer';
import distribuicaoProcessoReducer from '../components/distribuicaoProcesso/DistribuicaoProcessoReducer';
import vinculoProcessoReducer from '../features/detalheProcesso/components/vinculoProcesso/VinculoProcessoReducer';
import parteProcessoReducer from '../components/parteProcesso/ParteProcessoReducer';
import movimentacaoProcessoReducer from '../components/movimentacaoProcesso/MovimentacaoProcessoReducer';
import movimentacaoUnidadeReducer from '../features/movimentacaoUnidade/MovimentacaoUnidadeReducer';

export const rootReducer = combineReducers({
  alertReducer,
  orgaoReducer,
  situacaoReducer,
  router: routerReducer,
  pesquisaProcessoReducer,
  consultaProcessoReducer,
  detalheProcessoReducer,
  tramitacaoProcessoReducer,
  distribuicaoProcessoReducer,
  vinculoProcessoReducer,
  parteProcessoReducer,
  movimentacaoProcessoReducer,
  movimentacaoUnidadeReducer,
});

export default rootReducer;
