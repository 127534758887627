export const initialState = {
  loading: false,
  processos: [],
  error: false,
  rowCount: 0,
  totalPages: 0,
  cdOrgao: '',
  numeroMP: '',
  cdSituacao: '',
  tipoRelatorio: 0,
  parteInteressada: '',
  dataInicial: '',
  dataFinal: '',
  tipoProcesso: 0,
};

export default initialState;
