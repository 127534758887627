import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Popper from '@material-ui/core/Popper';
import { Typography } from '@bayon/commons';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Positive from '@bayon/svg-icons/icons/Positive';
import Negative from '@bayon/svg-icons/icons/Negative';
import FileAll from '@bayon/svg-icons/icons/FileAll';
import UserRound from '@bayon/svg-icons/icons/UserRound';
import CommentRound from '@bayon/svg-icons/icons/CommentRound';
import BadgeRound from '@bayon/svg-icons/icons/BadgeRound';

const styles = () => ({
  '@global': {
    '*::-webkit-scrollbar': { width: 5 },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: '#8b8778',
    },
  },
  wrapper: { height: 250 },
  container: {
    width: 250,
    height: 250,
    float: 'left',
    overflow: 'auto',
    wordWrap: 'break-word',
    position: 'relative',
  },
  divisor: {
    width: 1,
    height: 250,
    float: 'left',
    position: 'relative',
    backgroundColor: '#D4DEE9',
  },
  mouseOver: {
    padding: 5,
    paddingLeft: 15,
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#F4F4F4' },
  },
});

const PopperParteRepresentante = function PopperParteRepresentante(props) {
  const myRef = useRef();
  const [detail, setDetail] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes, listaPartes, popperIsOpen, fecharTodos, callbackIsOpen, callbackFecharTodos } = props;
  const [itemAtual, setItemAtual] = useState(listaPartes[0] ? listaPartes[0].numero_sequencial : -1);

  const open = Boolean(anchorEl) && popperIsOpen;
  const id = open ? 'simple-popper' : undefined;

  function getCorLinha(parte) {
    if (parte.numero_sequencial === itemAtual) {
      return '#CBE3F6';
    }
    return '';
  }

  const loadDetail = (event, parte) => {
    event.preventDefault();
    setItemAtual(parte.numero_sequencial);
    setDetail(parte.lista_representantes);
  };

  function handleClick(event) {
    event.preventDefault();
    callbackFecharTodos();
    callbackIsOpen();
    myRef.current = true;
    setAnchorEl(event.currentTarget);
    setDetail(listaPartes[0].lista_representantes);
    setItemAtual(listaPartes[0].numero_sequencial);
  }

  useEffect(() => {
    if (popperIsOpen && !myRef.current) {
      setAnchorEl(null);
    }
    myRef.current = false;
  }, [fecharTodos]);

  const manterAberto = (ev) => {
    ev.preventDefault();
    callbackIsOpen();
  };

  return (
    <div>
      <a href="#" id="linkMais" onClick={handleClick}>
        mais
      </a>
      <Popper id={id} open={open} anchorEl={anchorEl} transition onClick={manterAberto}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={0}>
            <Paper>
              <Typography style={{ padding: 15 }} size={12} weight="semibold">Partes e Representantes</Typography>
              <hr style={{ margin: 0 }} />
              <div className={classes.wrapper}>
                <div className={classes.container}>
                  {listaPartes.map(item => (
                    <Grid key={item.numero_sequencial} id={`item-parte-${item.numero_sequencial}`} container className={classes.mouseOver} style={{ backgroundColor: getCorLinha(item) }} alignItems="center" onClick={ev => loadDetail(ev, item)}>
                      <Grid item xs={1} style={{ paddingTop: 3 }}>
                        {item.tp_parte === 1 && (<Positive style={{ height: 14, width: 14 }} />)}
                        {item.tp_parte === 2 && (<Negative style={{ height: 14, width: 14 }} />)}
                        {item.tp_parte === 3 && (<FileAll style={{ height: 14, width: 14 }} fill="#C6ACDB" />)}
                        {[4, 5, 8, 9].includes(item.tp_parte) && (<UserRound style={{ height: 14, width: 14 }} fill="#808080" />)}
                        {item.tp_parte === 6 && (<CommentRound style={{ height: 14, width: 14 }} fill="#808080" />)}
                        {item.tp_parte === 7 && (<BadgeRound style={{ height: 14, width: 14 }} fill="#808080" />)}
                      </Grid>
                      <Grid item xs={11}>
                        <Typography size={12}>{item.nome_pessoa}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </div>
                <div className={classes.divisor} />
                <div className={classes.container}>
                  {!detail.length > 0 && (
                    <div style={{ position: 'absolute', left: 20, top: 100 }}>
                      <Typography size={14} color="neutralSecondaryAlt">Nenhum representante associado</Typography>
                    </div>
                  )}
                  {detail.map(item => (
                    <div style={{ padding: 10 }}>
                      <Typography size={12} color="neutralSecondaryAlt">{item.tipo_representante}</Typography>
                      <Typography size={12}>{item.nome_representante}</Typography>
                    </div>
                  ))}
                </div>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

PopperParteRepresentante.propTypes = {
  classes: PropTypes.object.isRequired,
  listaPartes: PropTypes.array.isRequired,
  popperIsOpen: PropTypes.bool.isRequired,
  fecharTodos: PropTypes.bool.isRequired,
  callbackIsOpen: PropTypes.func.isRequired,
  callbackFecharTodos: PropTypes.func.isRequired,
};

export default withStyles(styles)(PopperParteRepresentante);
