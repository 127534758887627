import request from '../../utils/request';
import {
  fetchMovimentacaoUnidadeAction,
  fetchMovimentacaoUnidadeActionError,
  fetchMovimentacaoUnidadeActionSuccess,
  fetchMovimentacaoUnidadeActionAnoMesInicialError,
  fetchMovimentacaoUnidadeActioAnoMesInicialSuccess,
} from './MovimentacaoUnidade.actions';

export const fetchMovimentacaoUnidade = (tpOrgao, anoMes) => async (dispatch) => {
  dispatch(fetchMovimentacaoUnidadeAction());
  try {
    const { data } = await request.post('/relatorio', { nome_relatorio: 'RelatorioEstatisticoPorOrgao_Excel', filtro: { anomes: anoMes, tporgao: tpOrgao } });
    dispatch(fetchMovimentacaoUnidadeActionSuccess(data.data));
  } catch (error) {
    if (!error.data) {
      dispatch(fetchMovimentacaoUnidadeActionError(error.message));
    } else {
      dispatch(fetchMovimentacaoUnidadeActionError(error.data));
    }
  }
};

export const fetchAnoMesInicial = () => async (dispatch) => {
  dispatch(fetchMovimentacaoUnidadeAction());
  try {
    const { data } = await request.get('/configuracoes');
    dispatch(fetchMovimentacaoUnidadeActioAnoMesInicialSuccess(data));
  } catch (error) {
    if (!error.data) {
      dispatch(fetchMovimentacaoUnidadeActionAnoMesInicialError(error.message));
    } else {
      dispatch(fetchMovimentacaoUnidadeActionAnoMesInicialError(error.data));
    }
  }
};
