export const initialState = {
  error: false,
  loading: false,
  listaParte: [],
  lastParte: false,
  cdProcesso: '',
  total: 0,
};

export default initialState;
