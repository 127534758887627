import request from '../../utils/request';
import {
  fetchParteProcessoAction,
  fetchParteProcessoActionError,
  fetchParteProcessoActionSuccess,
} from './ParteProcesso.actions';

export const fetchLoadMore = cdProcesso => async (dispatch) => {
  dispatch(fetchParteProcessoAction());
  try {
    const { data } = await request.get(`/consulta/partes-portaltransparencia?codigo_processo=${cdProcesso}&somente_antigas=false`);
    data.data.lastParte = true;
    data.data.cdProcesso = cdProcesso;
    dispatch(fetchParteProcessoActionSuccess(data.data));
  } catch (err) {
    dispatch(fetchParteProcessoActionError(err.data));
  }
};

export const fetchLoadLess = cdProcessoParte => async (dispatch) => {
  dispatch(fetchParteProcessoAction());
  try {
    const { data } = await request.get(`/consulta/partes-portaltransparencia?codigo_processo=${cdProcessoParte}&somente_antigas=true`);
    data.data.lastParte = false;
    data.data.cdProcesso = cdProcessoParte;
    dispatch(fetchParteProcessoActionSuccess(data.data));
  } catch (err) {
    dispatch(fetchParteProcessoActionError('Houve uma falha ao tentar carregar menos partes!'));
  }
};
