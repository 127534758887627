import request from '../../utils/request';
import { QUANTIDADE_PAGINACAO_DETALHE } from '../../commons/constants';
import {
  fetchTramitacaoProcessoAction,
  fetchTramitacaoProcessoActionError,
  fetchTramitacaoProcessoActionSuccess,
} from './TramitacaoProcesso.actions';

export const fetchLoadMoreTramitacoes = (cdProcesso, page, listaTramitacao) => async (dispatch) => {
  dispatch(fetchTramitacaoProcessoAction());
  try {
    const { data } = await request.get(`/consulta/tramitacoes-portaltransparencia?codigo_processo=${cdProcesso}&numero_pagina=${page}`);
    data.data.cdProcesso = cdProcesso;
    data.data.content = listaTramitacao.concat(data.data.content);
    data.data.numeroPaginaTramitacao = page;
    dispatch(fetchTramitacaoProcessoActionSuccess(data.data));
  } catch (err) {
    dispatch(fetchTramitacaoProcessoActionError(err.data));
  }
};

export const fetchLoadLessTramitacoes = (cdProcessoTramitacao, listaTramitacao) => async (dispatch) => {
  dispatch(fetchTramitacaoProcessoAction());
  try {
    const totalListaTramitacao = listaTramitacao.length;
    listaTramitacao.splice(QUANTIDADE_PAGINACAO_DETALHE, listaTramitacao.length - QUANTIDADE_PAGINACAO_DETALHE);
    const data = { cdProcesso: cdProcessoTramitacao, last: false, content: listaTramitacao, total_elements: totalListaTramitacao, numeroPaginaTramitacao: 0 };
    dispatch(fetchTramitacaoProcessoActionSuccess(data));
  } catch (err) {
    dispatch(fetchTramitacaoProcessoActionError('Houve uma falha ao tentar carregar menos tramitações!'));
  }
};
