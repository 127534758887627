export const initialState = {
  error: false,
  loading: false,
  listaVinculo: [],
  lastVinculo: false,
  cdProcesso: '',
  totalElements: 0,
  numeroPaginaVinculo: 0,
};

export default initialState;
